import React, {Component} from 'react'
import {connect} from 'react-redux'
import {createResource, deleteResource, getResource, updateResource} from '../../../data/actions/resource'
import {hideModal, showModal} from '../../../data/actions/ui'
import LocalStorage from '../../../common/util/localStorage'
import Resources from '../../../data/services/resources'
import {download} from '../../../data/actions/download'
import {Field, FieldsManager} from '../../../data/services/fields'
import IncomeExpenseDialog from '../dialogs/income-expense-dialog'
import PaymentDialog from '../dialogs/payment-dialog/payment-dialog'
import moment from 'moment-timezone'
import {CheckIcon, CreditCardIcon, DocumentDuplicateIcon, EyeIcon, PencilIcon} from '@heroicons/react/24/outline'
import Tippy from '@tippyjs/react'
import DocumentArrowDownIcon from '@heroicons/react/20/solid/DocumentArrowDownIcon'
import {
    CREATE_PERM,
    DEFAULT_CRUD_STATE,
    DEFAULT_HISTORY_TABLE_FILTER_FIELDS,
    DEFAULT_QUERY_LIMIT,
    DELETE_PERM,
    READ_PERM,
    UPDATE_PERM
} from '../../../util/util-constants'
import {
    checkPerm,
    classNames,
    getDefaultFieldOrder,
    getDefaultTableOptions,
    getDefaultTableOptionsJSON,
    getLookup,
    getProp,
    longTableColumn,
    mergeDeep,
    renderExpenseStatusBadge,
    resourceIsDeleted,
    returnOffsetAndPagination,
    saveTableColumns,
    verifyFilterFields
} from '../../../common/util/util-helpers'
import {currentDate, toFrontDate, toFrontDateTimeFromUTC} from '../../../common/util/util-dates'
import {cloneDeep, genericMoneyFormatter} from '../../../common/util/util-vanilla'
import TrashIcon from '@heroicons/react/24/outline/TrashIcon'
import InvoiceExpenseDetailsDialog from '../dialogs/invoice-expense-details-dialog'
import {createSecondResource, deleteSecondResource, getSecondResource} from '../../../data/actions/secondResource'
import {XMarkIcon} from '@heroicons/react/20/solid'
import {getThirdResource} from '../../../data/actions/thirdResource'
import CheckCircleIcon from '@heroicons/react/20/solid/CheckCircleIcon'
import PayMultipleBillsDialog from '../dialogs/pay-multiple-bills-dialog'
import PayDateFilter from '../carrier-pay/carrier-pay-date-filter'
import FireSolidIcon from '@heroicons/react/20/solid/FireIcon'
import FireIcon from '@heroicons/react/24/outline/FireIcon'
import {getHistoryResource} from '../../../data/actions/historyResource'
import {HourglassIcon} from '../../../data/themes/icons'
import BulkPaymentDialog from '../dialogs/bulk-payment-dialog'
import ContactInfoDialog from "../../../common/components/modal/contact-info-dialog";
import OrganizationInfoDialog from "../../../common/components/modal/modal-global/organization-info-dialog";
import ModalConfirm from "../../../common/components/modal/modal-confirm";
import Modal from "../../../common/components/modal";
import ModalDefault from "../../../common/components/modal/modal-default";
import TableOptionsDialog from "../../../common/components/resource-table/table-components/table-options-dialog";
import NoRecords from "../../../common/components/no-records-found/no-records";
import TableBulkActions from "../../../common/components/resource-table/table-components/table-bulk-actions";
import Pagination from "../../../common/components/resource-table/table-components/pagination";
import TableFilters from "../../../common/components/resource-table/table-components/table-filters";
import TableCard from "../../../common/components/resource-table/table-components/table-card";
import TableCardFooter from "../../../common/components/resource-table/table-components/table-card-footer";
import ResourceTable from "../../../common/components/resource-table";
import TableSettingsPopOver from "../../../common/components/resource-table/table-components/table-settings-popover";
import ActiveFilters from "../../../common/components/resource-table/table-components/active-filters";
import {excludeFields} from "../../../common/util/util-fields";
import CellButton from "../../../common/components/resource-table/table-components/cell-button";
import HistoryModal, {DEFAULT_HISTORY_TABLE_FIELDS} from "../../../common/components/modal/modal-history";
import Button from '../../../common/components/button'

class BillsTab extends Component {

    constructor(props) {
        super(props)
        this.colors = ['#171219', '#225560', '#E9DF00', '#F0803C', '#FF66B3', '#084B83', '#42BFDD', '#72BDA3', '#03FCBA', '#770058']

        this.pagePath = this.props.location.pathname.substring(1) + '_bills'

        this.tablePageDefaults = {
            behaviour: {
                rowSelect: true,
                canAdjustWidth: true,
                hasMenu: true
            },
            columns: {
                AutoCounter: {
                    minWidth: 85,
                },
                Labels: {minWidth: 100},
                RefNumber: {minWidth: 105},
                Amount: {minWidth: 120},
                AmountTransferred: {minWidth: 120},
                AmountAdvance: {minWidth: 120},
                AmountAdvanceTransferred: {minWidth: 120},
                IsLoadInvoice: {minWidth: 50},
                InvoiceType: {minWidth: 80},
                Date: {minWidth: 130},
                DueDate: {minWidth: 130},
                BookDate: {minWidth: 130},
                InvoiceStatus: {minWidth: 100},
                IsDigitalSent: {minWidth: 80},
                IsDigitalConfirmed: {minWidth: 80},
            },
            style: {
                condensed: true,
                isGPUAccelerated: true
            }
        }

        this.state = {
            // Default state
            ...DEFAULT_CRUD_STATE,
            sortBy: 'DueDate',
            sort: 'ASC',

            // Fields
            tableFields: this.getTableFields(),
            fieldsOrder: getDefaultFieldOrder(this.getTableFields(), this.pagePath),
            tableOptions: getDefaultTableOptions(this.getTableFields(), this.tablePageDefaults, this.pagePath, this.props.translate),

            // Filter, and header fields
            queryFilterFields: this.getQueryFilterFields(),

            selectedItems: {},
            selectedRows: {},
            isTableFullScreen: false,

            // Dialog(s)
            isInvoiceDialogVisible: false,
            incomeExpenseDialog: false,
            deleteDialog: false,
            approveDialog: false,
            isLimitBelowRequiredDialogOpen: false,

            batchActionDialog: false,
            batchActionDialogHideAnimation: false,

            isSendPaymentDialogVisible: false,
            showConfirm: false,
            isExpenseCopyDialogVisible: false
        }

        if (!this.state.tableOptions.behaviour.hasMenu) { // TEMP FUNCTION
            this.state.tableOptions.behaviour = Object.assign(this.state.tableOptions.behaviour, this.tablePageDefaults.behaviour)
        }

        this.PopoverButtonRef = React.createRef()

        this.state = mergeDeep(this.state, verifyFilterFields(
            LocalStorage.rehydrateState(this.pagePath),
            this.state.queryFilterFields
        ))
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData()
        this.fetchLabels()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (resourceIsDeleted(this.props.secondResource, prevProps.secondResource)) {
            this.fetchInvoiceDialogData()
            this.fetchData()
        }

        if (prevProps.breakpoint !== this.props.breakpoint && this.props.breakpoint.index <= 1 && this.state.queryFilterFields.limit.value !== 10) {
            this.handleFilterInputChange('limit', 10)
        }
    }

    /** Data Events
     ================================================================= */

    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get('user'),
            resource: this.getResourceName(),
            query: this.getQuery()
        }))
    }

    fetchLabels = () => {
        this.props.dispatch(getThirdResource({
            user: LocalStorage.get('user'),
            resource: Resources.ColorTag
        }))
    }

    handleSetMarkedClick = (isMarkAction = true) => {
        const selectedRows = this.state.selectedRows

        const ExpenseIDs = Object.keys(selectedRows)

        this.props.dispatch(createResource({
            user: LocalStorage.get('user'),
            params: {
                InvoiceIDs: [],
                ExpenseIDs: ExpenseIDs,
                Marked: isMarkAction ? 1 : 0
            },
            query: this.getQuery(),
            resource: Resources.AccountingMark,
            piggyResource: this.getResourceName(),
            errorMessage: true,
            successMessage: isMarkAction ? this.props.translate('message.selection_add_marked') : this.props.translate('message.selection_remove_marked')
        }))

        this.setState({selectedRows: {}})
    }

    fetchInvoiceDialogData = () => {
        this.props.dispatch(getSecondResource({
            user: LocalStorage.get('user'),
            query: {
                ExpenseID: this.state.selectedItem.ExpenseID
            },
            resource: Resources.ExpenseExpense
        }))
    }

    deleteResource = (item) => {
        this.handleToggleDeleteDialog(() => {
            this.setState({
                ...returnOffsetAndPagination(getProp(this.props.resource.data, 'list', []), this.getQuery(), this.state.paginationPage),
            }, () => {
                this.props.dispatch(deleteResource({
                    user: LocalStorage.get('user'),
                    query: Object.assign({}, this.getQuery(), {
                        ExpenseID: item.ExpenseID
                    }),
                    errorMessage: true, successMessage: `${item.AutoCounter} deleted.`,
                    resource: Resources.Expense,
                    piggyResource: Resources.Expense
                }))
                this.handleToggleDeleteDialog()
                const rows = Object.values(Object.assign({}, this.state.selectedRows)).reduce((memo, it) => {
                    if (it.ExpenseID !== item.ExpenseID) {
                        memo[it.ExpenseID] = it
                    }
                    return memo
                }, {})
                this.setSelectedRows(rows)
            })
        }, item)
    }

    approveResource = (item) => {
        const contactApprovalLimit = getProp(this.props.resource.data, 'Contact.MaxExpenseValue', 0)
        const expenseAmount = item.Amount

        if (contactApprovalLimit >= expenseAmount) {
            this.handleToggleApproveDialog(() => {
                this.props.dispatch(updateResource({
                    user: LocalStorage.get('user'),
                    params: {id: item.ExpenseID},
                    errorMessage: true, successMessage: `${item.ExpenseID} approved.`,
                    resource: Resources.ExpenseApprove,
                    piggyResource: Resources.Expense,
                    query: this.getQuery()
                }))
                this.setState({showConfirm: !this.state.showConfirm})
            })
        } else {
            this.toggleLimitBelowRequiredDialog()
        }
    }

    downloadExcel = () => {
        this.props.dispatch(download({
            user: LocalStorage.get('user'),
            resource: Resources.Expense,
            query: Object.assign(
                {format: 'EXCEL', name: 'expenses_bills_' + currentDate() + '.xlsx'},
                this.getQuery()
            )
        }))
    }

    submitBulkPayment = (fields, payments) => {
        this.props.dispatch(createResource({
            user: LocalStorage.get('user'),
            params: Object.assign({}, FieldsManager.getFieldKeyValues(fields), {
                morePayments: payments.map(item => {
                    return {
                        ExpenseID: item.ExpenseID,
                        Amount: item.EnteredAmount,
                    }
                })
            }),
            resource: Resources.ExpensePayment,
            piggyResource: this.getResourceName(),
            query: this.getQuery(),
            errorMessage: true,
            successMessage: this.props.translate('text.multiple_bills_paid')
        }))
        this.setState({selectedItems: {}, selectedRows: {}})
    }

    /** Fields definitions
     ================================================================= */
    getTableFields = () => {
        return {
            Date: new Field('Date', '', [''], false, 'date', {
                render: (item) => {
                    return <div className="flex gap-1.5 items-center justify-center">
                        {!!item.Marked && (
                            <div className="ml-auto flex pl-5">
                                <Tippy
                                    content={this.props.translate('field.Marked')}
                                >
                                        <span className="relative">
                                            <FireIcon
                                                className="relative z-12 h-5 w-5 cursor-pointer text-red-600 animate-pulse"/>
                                        </span>
                                </Tippy>
                            </div>
                        )}
                        {toFrontDate(item.Date)}

                    </div>
                }
            }),
            BookDate: new Field('BookDate', '', [''], false, 'date', {hideDialog: true}),
            DueDate: new Field('DueDate', '', [''], false, 'date'),
            PayTo: new Field('PayTo', '', [''], false, 'custom', {
                render: (item) =>
                    <CellButton
                        onClick={() => item.OrganizationID ? this.handleToggleOrganizationInfoDialog(item) : this.handleToggleContactInfoDialog(item)}>
                        <div>
                            {item.ContactID ? item.Contact : item.Organization}
                        </div>
                    </CellButton>,
            }),
            RefNumber: new Field('RefNumber', '', [], false, 'text'),
            Amount: new Field('Amount', '', [], false, 'money'),
            AmountTransferred: new Field('AmountTransferred', '', [''], false, 'money', {
                addTableHeaderClass: 'ml-auto',
                render: (item) => {
                    return <div
                        className={'flex justify-end w-full'}>{genericMoneyFormatter(item.Amount - item.AmountTransferred)}</div>
                }
            }),
            AmountAdvance: new Field('AmountAdvance', '', [''], false, 'money', {
                addTableHeaderClass: 'ml-auto',
                render: (item) => {
                    return <div className={'flex justify-end w-full'}>{genericMoneyFormatter(item.AmountAdvance)}</div>
                }
            }),
            AmountAdvanceTransferred: new Field('AmountAdvanceTransferred', '', [''], false, 'money', {
                addTableHeaderClass: 'ml-auto',
                render: (item) => {
                    return <div
                        className={'flex justify-end w-full'}>{genericMoneyFormatter(item.AmountAdvance - item.AmountAdvanceTransferred)}</div>
                }
            }),
            InternalNotes: new Field('InternalNotes', '', [], false, 'custom', {
                render: (item) => (
                    <div>{longTableColumn(item.InternalNotes)}</div>
                )
            }),
            ExpenseStatusID: new Field('ExpenseStatusID', '', [''], false, 'custom', {
                render: (it) => renderExpenseStatusBadge(it)
            }),
            IsApproved: new Field('IsApproved', '', [''], false, 'custom', {
                render: this.renderIsApproved
            }),
        }
    }

    getFields = () => {
        return {
            TotalAmount: new Field('TotalAmount', '', ['float']),
            ContactID: new Field('ContactID', '', ['empty'], false, 'select-search'),
            OrganizationID: new Field('OrganizationID', '', ['empty'], false, 'select-search'),
            Description: new Field('Description', '', ['empty']),
            Date: new Field('Date', '', ['empty'], false, 'date'),
            InternalNotes: new Field('InternalNotes', '', [], false, 'textarea')
        }
    }

    getQueryFilterFields = () => {
        return {
            query: new Field('query', '', [''], false, 'search', {}, {}),
            ExpenseStatusID: new Field('ExpenseStatusID', 1, [''], false, 'select', {}, {
                all: true,
                values: getLookup('ExpenseStatus'),
            }),
            StartDate: new Field('StartDate', '', [''], false, 'date', {addContainerClass: 'col-span-full'}, {}),
            EndDate: new Field('EndDate', '', [''], false, 'date', {addContainerClass: 'col-span-full'}, {}),
            ContactID: new Field('ContactID', '', [], false, 'select-search', {}, {}),
            OrganizationID: new Field('OrganizationID', '', [], false, 'select-search', {}, {}),
            AmountMoreThan: new Field('AmountMoreThan', '', [], false, 'float'),
            AmountLessThan: new Field('AmountLessThan', '', [], false, 'float'),
            AdvanceMoreThan: new Field('AdvanceMoreThan', '', [], false, 'float'),
            AdvanceLessThan: new Field('AdvanceLessThan', '', [], false, 'float'),
            limit: new Field('limit', DEFAULT_QUERY_LIMIT, [''], false, 'select', {
                hideLabel: true,
                labelType: 'float'
            }, {menuPlacement: 'top'})
        }

    }

    getFieldTypes = () => {
        let fieldsCombined = Object.assign({}, this.getTableFields())

        fieldsCombined = Object.values(fieldsCombined).reduce((memo, it) => {
            memo[it.name] = it.type
            return memo
        }, {})

        fieldsCombined['AmountTransferred'] = 'money'
        fieldsCombined['Marked'] = 'checkbox'
        fieldsCombined['IsApproved'] = 'checkbox'
        fieldsCombined['IsDelayedPayment'] = 'checkbox'
        fieldsCombined['IsRevised'] = 'checkbox'
        fieldsCombined['HasReference'] = 'checkbox'

        return Object.assign({}, fieldsCombined)
    }

    /** UI Events (pagination, filters, sort)
     ================================================================= */
    handleInputChange = (name, value) => {
        this.setState({fields: FieldsManager.updateField(this.state.fields, name, value)}, this.fetchData)
    }

    handleUpdateSort = (sortBy) => {
        let sort = (this.state.sortBy === sortBy) ? (this.state.sort === 'ASC' ? 'DESC' : 'ASC') : 'ASC'
        if (!sortBy) {
            sortBy = 'DueDate'
            sort = 'ASC'
        }

        this.setState({
            sortBy: sortBy,
            sort: sort
        }, () => {
            this.fetchData()
            this.saveFilters()
        })
    }

    handleClearFiltersClick = (excludeAdditional = []) => {
        const queryFilterFields = cloneDeep(this.state.queryFilterFields)
        const defaultExcludedFields = ['limit']
        const excludedFields = defaultExcludedFields.concat(excludeAdditional)

        Object.values(queryFilterFields).filter(it => !excludedFields.includes(it.name)).forEach(it => {
            FieldsManager.updateField(queryFilterFields, it.name, '')
        })

        queryFilterFields.StartDate.props.maxDate = ''
        queryFilterFields.EndDate.props.minDate = ''

        this.setState({
            queryFilterFields: queryFilterFields,
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleUpdateOffset = (offset, num) => {
        this.setState({
            offset: offset,
            paginationPage: num
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleToggleDeleteDialog = (submit, item, restore = false) => {
        this.setState({
            confirmText: !restore ? this.props.translate('message.confirm_delete_expense', [item?.AutoCounter]) : this.props.translate('message.confirm_restore_generic') + ` Expense: ${item?.ExpenseID}`,
            deleteDialog: submit
        })
    }

    handleToggleApproveDialog = (submit) => {
        this.setState({
            confirmText: this.props.translate('text.ApproveEntry'),
            approveDialog: submit,
            showConfirm: !this.state.showConfirm
        })
    }

    toggleLimitBelowRequiredDialog = () => {
        this.setState({
            isLimitBelowRequiredDialogOpen: !this.state.isLimitBelowRequiredDialogOpen
        })
    }

    handleCloseModalClick = (name) => {
        this.setState({selectedItem: null})
        this.props.dispatch(hideModal(name))
    }

    handleToggleSendPaymentDialog = (item) => {
        this.setState({
            sendPaymentItem: item,
            isSendPaymentDialogVisible: !this.state.isSendPaymentDialogVisible
        })
    }

    handleToggleShowDetails = (item, i) => {
        this.setState({
            isDetailsDialogVisible: !this.state.isDetailsDialogVisible,
            selectedItem: item
        })
    }

    closeBatchActionDialog = () => {
        this.setState({batchActionDialogHideAnimation: true}, () => {
            setTimeout(() => {
                this.setState({batchActionDialog: false, batchActionDialogHideAnimation: false})
            })
        })
    }

    handleSelectRowClick = (item, event) => {
        const itemID = item.ExpenseID
        const data = getProp(this.props.resource.data, 'list', [])
        let selectedRows = cloneDeep(this.state.selectedRows)
        let selectedItems = this.state.selectedItems
        if (event?.nativeEvent?.shiftKey) {
            const firstSelectedItem = data.findIndex(it => selectedRows[it.ExpenseID])
            const lastSelectedItem = data.findIndex(it => it.ExpenseID === itemID)

            if (firstSelectedItem > -1 && lastSelectedItem > -1) {
                if (firstSelectedItem < lastSelectedItem) {
                    for (let i = firstSelectedItem; i < lastSelectedItem + 1; i++) {
                        Object.assign(selectedRows, {[data[i].ExpenseID]: data[i]})
                        selectedItems[data[i].ExpenseID] = data[i]
                    }
                } else {
                    for (let i = lastSelectedItem; i < firstSelectedItem + 1; i++) {
                        selectedItems[data[i].ExpenseID] = data[i]
                        Object.assign(selectedRows, {[data[i].ExpenseID]: data[i]})
                    }
                }
            }
        } else {
            if (!!selectedRows[itemID]) {
                delete selectedItems[itemID]
                delete selectedRows[itemID]
            } else {
                selectedItems[itemID] = data.find(it => it.ExpenseID === itemID)
                Object.assign(selectedRows, {[itemID]: item})
            }
        }

        this.setState({selectedRows, selectedItems})
    }

    handleSelectAllClick = (selectAll) => {
        const data = cloneDeep(getProp(this.props.resource.data, 'list', []))

        let selectedRows = cloneDeep(this.state.selectedRows)
        let selectedItems = this.state.selectedItems

        if (!selectAll) {
            Object.assign(selectedRows, data.reduce((memo, it) => {
                selectedItems[it.ExpenseID] = it
                memo[it.ExpenseID] = it
                return memo
            }, {}))
        } else {
            let selectedRowsKeys = Object.keys(selectedRows)
            data.forEach(it => {
                if (selectedRowsKeys.includes(it.ExpenseID.toString())) {
                    delete selectedItems[it.ExpenseID]
                    delete selectedRows[it.ExpenseID]
                }
            })
        }

        this.setState({selectedRows, selectedItems})
    }

    handleFilterInputChange = (name, value) => {
        const queryFilterFields = FieldsManager.updateField(this.state.queryFilterFields, name, value)

        queryFilterFields.StartDate.props.maxDate = queryFilterFields.EndDate.value
        queryFilterFields.EndDate.props.minDate = queryFilterFields.StartDate.value

        this.setState({
            queryFilterFields: FieldsManager.updateField(this.state.queryFilterFields, name, value),
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleSetTableColumnOptions = (columns) => {
        let tableOptions = cloneDeep(this.state.tableOptions)

        tableOptions.columns = columns.reduce((memo, it) => {
            memo[it.name] = it
            return memo
        }, {})

        this.setState({
            tableOptions,
            columnsModalOpen: false
        }, () => saveTableColumns(this.pagePath, tableOptions))
    }

    handleToggleColumnSettingsDialog = () => {
        this.setState({
            columnsModalOpen: !this.state.columnsModalOpen
        })
    }

    handleTogglePayMultipleBills = () => {
        this.setState({payMultipleBillsModalOpen: !this.state.payMultipleBillsModalOpen})
    }

    handleOpenInvoiceDialog = (description) => {
        this.setState({
            isInvoiceDialogVisible: true
        })
    }

    handleCloseInvoiceDialog = () => {
        this.setState({
            isInvoiceDialogVisible: false,
            dialogHideAnimation: false
        })
    }

    handleToggleHistoryModal = (item = null) => {
        this.setState({
            historyModalOpen: !this.state.historyModalOpen,
            selectedItem: item
        })
    }

    toggleIncomeExpenseDialog = (item = null) => {
        this.setState({
            incomeExpenseDialog: !this.state.incomeExpenseDialog,
            selectedItem: item
        })
    }

    handleFullScreenToggle = () => {
        this.setState({
            isTableFullScreen: !this.state.isTableFullScreen
        })
    }

    openCopyExpenseDialog = (item = null) => {
        this.setState({
            isExpenseCopyDialogVisible: true,
            selectedItem: item
        })
    }

    closeCopyExpenseDialog = () => {
        this.setState({
            isExpenseCopyDialogVisible: false,
            dialogHideAnimation: false
        })
    }
    /** Helpers
     ================================================================= */
    getDefaultTableColumnOptions = () => {
        return getDefaultTableOptionsJSON(this.getTableFields(), this.tablePageDefaults, this.props.translate)
    }

    editHidden = (item) => {
        const LockedData = getProp(this.props.resource.data, 'defaults.LockedDate', null)
        return !((!LockedData || (moment(LockedData).diff(item?.Date) < 0))
            && !item?.IsPayrollExpense
        )
    }

    deleteHidden = (item) => {
        const LockedData = getProp(this.props.resource.data, 'defaults.LockedDate', null)
        return !((!LockedData || (moment(LockedData).diff(item.Date) < 0))
            && !item.IsPayrollExpense
            && !(item.AmountTransferred > 0)
        )
    }

    saveFilters = () => {
        LocalStorage.persistState(this.pagePath, this.state,
            ['sortBy', 'sort', 'limit', 'offset', 'paginationPage', 'input', 'showArchived'])
    }

    getQuery = () => {
        return {
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            offset: this.state.offset,
            ...FieldsManager.getFieldKeyValues(this.state.queryFilterFields),
            TrueBill: 1
        }
    }

    setOptions = (options) => {
        this.setState({
            tableOptions: options
        }, () => saveTableColumns(this.pagePath, options))
    }

    canSendPayment = (item) => {
        return item.ExpenseStatusID == 1 && !item.IsDelayedPayment
    }

    getDialogTypeForItem = (item) => {
        if (item?.TypeID == 1) {
            if (item?.SubTypeID == 2) {
                return 'receipt'
            } else {
                return 'invoice'
            }
        } else if (item?.TypeID == 2) {
            if (item?.SubTypeID == 2) {
                return 'expense'
            } else {
                return 'bill'
            }
        }

        return null
    }

    getResourceName = () => {
        return Resources.Expense
    }

    handleToggleOrganizationInfoDialog = (item = {}) => {
        this.setState({
            selectedOrganization: item,
            isOrganizationInfoDialogOpen: true
        })
    }

    handleToggleContactInfoDialog = (item = {}) => {
        this.setState({
            selectedContact: item,
            isContactInfoDialogOpen: true
        })
    }

    setSelectedRows = (selectedRows) => {
        this.setState({selectedRows})
    }
    /** Render
     ================================================================= */

    renderIsApproved = (item) => {
        return (
            <div className={'pr-3'}>
                {!!item.IsApproved ?
                    <div className="pl-4">
                        <div className={'flex justify-center'}>
                            <CheckCircleIcon className="w-5 h-5 text-green-600"/>
                        </div>
                        <Tippy content={toFrontDateTimeFromUTC(item.ApprovedDateTime)}>
                            <div className={'text-center'}>{item.ApprovedByContact}</div>
                        </Tippy>
                    </div>
                    :
                    <div className="pl-4 flex justify-center">
                        <XMarkIcon className="w-5 h-5 text-tm-gray-400"/>
                    </div>
                }
            </div>
        )
    }

    render() {

        const {translate, resource, thirdResource, historyResource} = this.props
        const data = getProp(resource.data, 'list', [])
        const defaults = getProp(resource.data, 'defaults', [])
        const count = getProp(resource.data, 'count', 0)
        const isLoading = !!resource.isLoading

        const contactCanApproveExpense = getProp(resource.data, 'Contact.CanApproveExpense', 0)
        const contactApprovalLimit = getProp(resource.data, 'Contact.MaxExpenseValue', 0)
        const lowLimitInfoMessage = `You are allowed to approve expenses up to $${contactApprovalLimit} only, please contact your manager.`

        const hasSelectedRows = !!Object.keys(this.state.selectedRows).length
        const totalAmount = genericMoneyFormatter(Object.values(this.state.selectedRows).reduce((memo, it) => memo + it.Amount, 0))
        let queryFilterFields = excludeFields(cloneDeep(this.state.queryFilterFields), [
            'StartDate', 'EndDate'
        ])

        const areSomeItemsSelected = !!Object.keys(this.state.selectedRows).length

        return (
            <React.Fragment>
                <div className="flex mb-2">
                    <ActiveFilters
                        filterFields={this.state.queryFilterFields}
                        onLabelClick={this.handleFilterInputChange}
                        onClearFiltersClick={this.handleClearFiltersClick}
                        translate={translate}
                    />

                    <div className="flex ml-auto">
                        <div className={'flex mr-4'}>
                            <Tippy content={translate('text.download_excel')}>
                                <button
                                    className="btn-icon"
                                    onClick={this.downloadExcel}
                                >
                                    <DocumentArrowDownIcon className="w-5 h-5"/>
                                </button>
                            </Tippy>

                            <TableSettingsPopOver
                                options={this.state.tableOptions}
                                setOptions={this.setOptions}
                                toggleColumnSettingsDialog={this.handleToggleColumnSettingsDialog}
                                translate={translate}
                            />
                        </div>

                        <Button
                            hasPerm={checkPerm(Resources.Expense, CREATE_PERM)}
                            className={`btn btn-primary`}
                            onClick={() => {
                                this.handleOpenInvoiceDialog()
                            }}
                        >
                            {translate('btn.create_new')}
                        </Button>
                    </div>
                </div>

                <TableCard
                    addClass={
                        classNames(
                            this.state.isTableFullScreen
                                ? 'fixed inset-1 -top-1 z-50'
                                : undefined
                        )}
                >
                    <TableFilters
                        hideLimit
                        forceDialog={true}
                        filterFields={queryFilterFields}
                        handleInputChange={this.handleFilterInputChange}
                        translate={translate}
                        onRefreshTable={this.fetchData}
                        selects={{
                            limit: {10: '10', 20: '20', 30: '30', 40: '40', 50: '50'},
                        }}
                        onFullScreenToggle={this.handleFullScreenToggle}
                        isTableFullScreen={this.state.isTableFullScreen}
                        isLoading={isLoading}

                        customHtml={
                            <PayDateFilter
                                queryFields={this.state.queryFilterFields}
                                translate={translate}
                                updateQueryFields={(queryFieldsUpdate) => {
                                    this.setState({
                                        queryFilterFields: queryFieldsUpdate,
                                        offset: 0
                                    }, () => this.fetchData())
                                    this.saveFilters(queryFieldsUpdate)
                                }}
                                onQueryChange={this.handleFilterInputChange}
                                hasDateType={false}
                            />
                        }
                    />

                    <ResourceTable
                        data={data}
                        fields={this.getTableFields()}
                        verticalTableIsVisible={this.props.breakpoint.index <= 1}

                        translate={translate}
                        isLoading={resource.isLoading}

                        options={this.state.tableOptions}

                        limit={this.state.queryFilterFields.limit.value}

                        sort={this.state.sort}
                        sortBy={this.state.sortBy}
                        onSortChange={this.handleUpdateSort}

                        onRowClick={(item) => checkPerm(Resources.ExpenseExpense, READ_PERM) ? this.handleToggleShowDetails(item) : null}
                        saveTableOptions={this.setOptions}

                        actions={[
                            {
                                action: this.handleToggleShowDetails,
                                icon: EyeIcon,
                                hasPerm: checkPerm(Resources.ExpenseExpense, READ_PERM),
                                title: translate('text.show_details'),
                            },
                            {
                                action: this.toggleIncomeExpenseDialog,
                                icon: PencilIcon,
                                visible: (item) => !this.editHidden(item),
                                hasPerm: checkPerm(this.getResourceName(), UPDATE_PERM) && checkPerm(Resources.ExpenseExpense, READ_PERM),
                                title: translate('text.edit'),
                            },
                            {
                                action: this.handleToggleSendPaymentDialog,
                                icon: CreditCardIcon,
                                visible: (item) => this.canSendPayment(item),
                                title: translate('text.send_payment'),
                            },
                            {
                                action: this.approveResource,
                                icon: CheckIcon,
                                visible: (item) => contactCanApproveExpense && !item.IsApproved,
                                hasPerm: checkPerm(Resources.ExpenseApprove, UPDATE_PERM),
                                title: translate('btn.approve'),
                            },
                            {
                                action: this.deleteResource,
                                icon: TrashIcon,
                                visible: (item) => !this.deleteHidden(item),
                                hasPerm: checkPerm(this.getResourceName(), DELETE_PERM),
                                title: translate('btn.delete'),
                            },
                            {
                                action: this.handleToggleHistoryModal,
                                customContent: () => <HourglassIcon className="w-5 h-5"/>,
                                hasPerm: checkPerm(Resources.ExpenseHistory, READ_PERM),
                                title: translate('text.history'),
                            },
                            {
                                action: this.openCopyExpenseDialog,
                                icon: DocumentDuplicateIcon,
                                hasPerm: checkPerm(this.getResourceName(), CREATE_PERM),
                                title: translate('btn.copy')
                            }
                        ]}

                        onSelectRow={this.handleSelectRowClick}
                        selectedRows={this.state.selectedRows}
                        onSelectAllClick={this.handleSelectAllClick}
                        tableKey={'ExpenseID'}
                    />

                    {/*Table footer*/}
                    <TableCardFooter
                        show={!(!data.length && !resource.isLoading)}
                    >
                        <Pagination
                            count={count}
                            isLoading={resource.isLoading || thirdResource.isLoading}
                            hideRowsPerPage={this.props.breakpoint.index <= 1}
                            handleQueryChange={
                                (name, value, currentPage) => name === 'offset'
                                    ? this.handleUpdateOffset(value, currentPage)
                                    : this.handleFilterInputChange(name, value)
                            }
                            pageOffset={this.state.offset}
                            queryFields={this.state.queryFilterFields}
                            translate={translate}
                        />
                    </TableCardFooter>

                    <TableBulkActions
                        selectedRows={this.state.selectedRows}
                        tableKey="ExpenseID"
                        fields={this.getFields()}
                        translate={translate}
                        onSelectAllClick={this.handleSelectAllClick}
                        options={this.state.tableOptions}
                        setSelectedRows={this.setSelectedRows}
                        footerContent={
                            <div className="text-sm flex justify-between w-full px-4 py-1 h-8">
                                <div className="whitespace-nowrap">
                                    Total amount for selected entries:
                                    <span className="ml-2 font-bold text-primary-contrast">
                                        {totalAmount}
                                    </span>
                                </div>
                            </div>
                        }
                    >
                        <div className="flex items-center h-8">
                            <div className={classNames('flex divide-x-2 divide-primary-shade pr-2')}>

                                {checkPerm(Resources.AccountingMark, UPDATE_PERM) && (
                                    <div className="flex gap-x-1 pr-2">
                                        <Tippy content={translate('btn.set_as_marked')}>
                                            <button
                                                onClick={() => this.handleSetMarkedClick(true)}
                                                disabled={!areSomeItemsSelected}
                                                className={classNames(areSomeItemsSelected ? 'hover:bg-primary-shade' : undefined, 'icon-btn p-1 flex items-center')}
                                            >
                                                <FireSolidIcon
                                                    className={classNames(areSomeItemsSelected ? 'text-primary-contrast' : 'text-tm-gray-400', 'w-5 h-5')}/>
                                            </button>
                                        </Tippy>

                                        <Tippy content={translate('btn.remove_from_marked')}>
                                            <button
                                                onClick={() => this.handleSetMarkedClick(false)}
                                                disabled={!areSomeItemsSelected}
                                                className={classNames(areSomeItemsSelected ? 'hover:bg-primary-shade' : undefined, 'icon-btn p-1 flex items-center')}
                                            >
                                                <FireIcon
                                                    className={classNames(areSomeItemsSelected ? 'text-primary-contrast' : 'text-tm-gray-400', 'w-5 h-5')}/>
                                            </button>
                                        </Tippy>
                                    </div>
                                )}

                                <div className="px-2 flex gap-x-1">
                                    {checkPerm(Resources.ExpensePayment, UPDATE_PERM) && (
                                        <Tippy content={translate('text.PayMultipleBills')}>
                                            <button
                                                onClick={() => this.setState({batchActionDialog: true})}
                                                disabled={!hasSelectedRows}
                                                className={classNames(hasSelectedRows ? 'hover:bg-primary-shade' : undefined, 'icon-btn p-1 flex items-center')}
                                            >
                                                <CreditCardIcon
                                                    className={classNames(hasSelectedRows ? 'text-primary-contrast' : 'text-tm-gray-400', 'w-5 h-5')}/>
                                            </button>
                                        </Tippy>
                                    )}

                                </div>
                            </div>
                        </div>
                    </TableBulkActions>

                    <NoRecords
                        show={(data.length === 0) && !resource.isLoading}
                        title={translate('text.no_records')}
                        addClass={'pt-16 pb-10'}
                    />
                </TableCard>

                <Modal
                    show={!!this.state.payMultipleBillsModalOpen}
                    onClose={this.handleTogglePayMultipleBills}
                    widthClass={'max-w-full'}
                >
                    {this.state.payMultipleBillsModalOpen && (
                        <PayMultipleBillsDialog
                            onRemoveFromSelected={this.handleSelectRowClick}
                            onClose={this.handleTogglePayMultipleBills}
                            translate={translate}
                            selectedItems={this.state.selectedItems}
                            onSubmit={(params) => {
                                this.props.dispatch(createResource({
                                    user: LocalStorage.get('user'),
                                    params: Object.assign({}, {
                                        morePayments: params
                                    }),
                                    resource: Resources.ExpensePayment,
                                    piggyResource: this.getResourceName(),
                                    query: this.getQuery(),
                                    errorMessage: true, successMessage: translate('text.multiple_bills_paid')
                                }))
                                this.setState({
                                    selectedItems: {},
                                    selectedRows: {}
                                })
                                this.handleTogglePayMultipleBills()
                            }}
                        />
                    )}
                </Modal>

                {this.state.isInvoiceDialogVisible && (
                    <IncomeExpenseDialog
                        isExpenseDialogType={true}
                        onClose={this.handleCloseInvoiceDialog}
                        defaults={defaults}
                        query={this.getQuery()}
                        onSubmit={(params, resource, files, fileResource, notePayableFields) => {
                            if (!!notePayableFields) {
                                Object.assign(params, notePayableFields)
                            }

                            if (params) {
                                if (params.id) {
                                    this.props.dispatch(updateResource({
                                        user: LocalStorage.get('user'),
                                        query: this.getQuery(),
                                        params: params,
                                        resource: resource,
                                        file: files,
                                        fileResource: Resources.ExpenseDocuments,
                                        piggyResource: Resources.Expense,
                                        errorMessage: true, successMessage: translate('text.entry_updated')
                                    }))
                                } else {
                                    this.props.dispatch(createResource({
                                        user: LocalStorage.get('user'),
                                        query: this.getQuery(),
                                        params: params,
                                        resource: resource,
                                        file: files,
                                        fileResource: Resources.ExpenseDocuments,
                                        piggyResource: Resources.Expense,
                                        errorMessage: true, successMessage: translate('text.entry_created')
                                    }))
                                }
                                this.handleCloseInvoiceDialog()
                            }
                        }}
                        {...this.props}
                    />
                )}

                <TableOptionsDialog
                    show={this.state.columnsModalOpen}
                    pagePath={this.pagePath}
                    columns={this.state.tableOptions.columns}
                    setTableColumnOptions={this.handleSetTableColumnOptions}
                    getDefaultTableColumnOptions={this.getDefaultTableColumnOptions}
                    onClose={this.handleToggleColumnSettingsDialog}
                    translate={translate}
                />

                <ModalDefault
                    show={this.state.isDetailsDialogVisible}
                    widthClass={'max-w-full'}
                    title={translate('text.ExpenseID')}

                    buttonDisabled={this.editHidden(this.state.selectedItem) || !checkPerm(Resources.ExpenseExpense, UPDATE_PERM)}
                    onButtonClick={() => {
                        this.handleToggleShowDetails();
                        this.toggleIncomeExpenseDialog(this.state.selectedItem)
                    }}
                    buttonLabel={translate('btn.edit')}
                    closeButtonLabel={translate('btn.Close')}
                    onClose={() => this.handleToggleShowDetails()}
                >
                    <InvoiceExpenseDetailsDialog
                        resource={this.props.secondResource}
                        dialogResource={this.props.dialogResource}
                        isLoading={this.props.secondResource.isLoading || this.props.resource.isLoading}
                        translate={translate}
                        dispatch={this.props.dispatch}
                        selectedItem={this.state.selectedItem}
                        type="expense"
                        expenseID={this.state.selectedItem?.ExpenseID}
                        canDeletePayment
                        showLoadReferenceLinks
                        createCommentDispatchAction={createSecondResource}
                        deleteCommentDispatchAction={deleteSecondResource}

                        onDocumentDownload={(item) => {
                            const fileExt = item?.AttachPath.split('.').pop()

                            this.props.dispatch(download({
                                user: LocalStorage.get('user'),
                                resource: Resources.ExpenseDocuments,
                                query: Object.assign({
                                    id: item.ExpenseID,
                                    format: fileExt,
                                    name: 'document_' + currentDate() + '.' + fileExt
                                })
                            }))
                        }}

                        onFetchData={() => {
                            if (this.state.selectedItem) {
                                this.fetchInvoiceDialogData()
                            }
                        }}

                        onDeletePayment={(item) => {
                            this.props.dispatch(deleteSecondResource({
                                user: LocalStorage.get('user'),
                                resource: Resources.ExpensePayment,
                                query: {
                                    SendPaymentID: item.SendPaymentID
                                },
                                errorMessage: true,
                                successMessage: translate('text.PaymentDeletedSuccessfully')
                            }))
                        }}
                    />
                </ModalDefault>

                {this.state.incomeExpenseDialog && (
                    <IncomeExpenseDialog
                        isInvoiceDialogType={this.state.selectedItem?.Type == 1}
                        isExpenseDialogType={this.state.selectedItem?.Type == 2}
                        onClose={this.toggleIncomeExpenseDialog}
                        query={this.getQuery()}
                        dialogType={this.getDialogTypeForItem(this.state.selectedItem)}
                        updateItem={this.state.selectedItem}
                        onSubmit={(params, resource, files, fileResource) => {
                            if (params) {
                                if (params.id) {
                                    this.props.dispatch(updateResource({
                                        user: LocalStorage.get('user'),
                                        query: this.getQuery(),
                                        params: params,
                                        resource: resource,
                                        file: files,
                                        fileResource: fileResource,
                                        piggyResource: this.getResourceName(),
                                        errorMessage: true, successMessage: translate('text.entry_updated')
                                    }))
                                } else {
                                    this.props.dispatch(createResource({
                                        user: LocalStorage.get('user'),
                                        query: this.getQuery(),
                                        params: params,
                                        resource: resource,
                                        file: files,
                                        fileResource: fileResource,
                                        piggyResource: this.getResourceName(),
                                        errorMessage: true, successMessage: translate('text.entry_created')
                                    }))
                                }
                                this.toggleIncomeExpenseDialog()
                            }
                        }}
                        {...this.props}
                    />
                )}

                {this.state.isExpenseCopyDialogVisible && (
                    <IncomeExpenseDialog
                        isInvoiceDialogType={this.state.selectedItem?.Type == 1}
                        isExpenseDialogType={this.state.selectedItem?.Type == 2}
                        onClose={this.closeCopyExpenseDialog}
                        query={this.getQuery()}
                        dialogType={this.getDialogTypeForItem(this.state.selectedItem)}
                        updateItem={this.state.selectedItem}
                        isCopy={true}
                        onSubmit={(params, resource, files, fileResource) => {
                            if (params) {
                                params.Items = params.Items.map(it => {
                                    delete it['ExpenseLineID']
                                    return it
                                })
                                this.props.dispatch(createResource({
                                    user: LocalStorage.get('user'),
                                    query: this.getQuery(),
                                    params: params,
                                    resource: resource,
                                    file: files,
                                    fileResource: fileResource,
                                    piggyResource: this.getResourceName(),
                                    errorMessage: true, successMessage: translate('text.entry_created')
                                }))
                            }
                            this.closeCopyExpenseDialog()
                        }
                        }
                        {...this.props}
                    />
                )}

                <PaymentDialog
                    show={this.state.isSendPaymentDialogVisible}
                    payment={'send'}
                    item={this.state.sendPaymentItem}
                    resourcePath={Resources.ExpensePayment}
                    piggyResourcePath={Resources.Expense}
                    defaults={defaults}
                    query={this.getQuery()}
                    onClose={() => this.handleToggleSendPaymentDialog()}
                    {...this.props}
                />

                <BulkPaymentDialog
                    payment={'send'}
                    show={this.state.batchActionDialog}
                    translate={translate}
                    HideModalAnimation={this.state.batchActionDialogHideAnimation}
                    onClose={this.closeBatchActionDialog}
                    selectedItems={this.state.selectedItems}
                    onSubmit={this.submitBulkPayment}
                    defaults={defaults}
                    itemKeyLabel={'ExpenseID'}
                    {...this.props}
                />

                <ModalConfirm
                    title={'Confirm'}
                    show={!!this.state.deleteDialog}
                    text={this.state.confirmText}
                    onClose={() => this.handleToggleDeleteDialog()}
                    buttonLabel={translate('btn.confirm')}
                    closeButtonLabel={'Cancel'}
                    translate={translate}
                    onConfirm={this.state.deleteDialog}
                />

                {this.state.isLimitBelowRequiredDialogOpen && (
                    <ModalConfirm
                        title={translate('text.low_limit')}
                        type={'info'}
                        show={this.state.isLimitBelowRequiredDialogOpen}
                        text={lowLimitInfoMessage}
                        textClassName={'text-sm text-tm-gray-900'}
                        onClose={() => this.toggleLimitBelowRequiredDialog()}
                        buttonLabel={translate('btn.confirm')}
                        onConfirm={() => this.toggleLimitBelowRequiredDialog()}
                    />
                )}

                <Modal
                    show={this.state.historyModalOpen}
                    widthClass={'max-w-full'}
                    onClose={() => this.setState({historyModalOpen: false})}
                    translate={translate}
                >
                    {this.state.historyModalOpen && (
                        <HistoryModal
                            onClose={() => this.setState({historyModalOpen: false})}
                            translate={translate}
                            title={translate('text.' + Resources.ExpenseHistory)}
                            tableFields={DEFAULT_HISTORY_TABLE_FIELDS(translate, this.getFieldTypes())}
                            filterFields={DEFAULT_HISTORY_TABLE_FILTER_FIELDS()}
                            onFetchData={(query) => {
                                this.props.dispatch(getHistoryResource({
                                    user: LocalStorage.get('user'),
                                    query: Object.assign({}, query, {
                                        id: this.state.selectedItem.ExpenseID
                                    }),
                                    resource: Resources.ExpenseHistory
                                }))
                            }}
                            data={getProp(historyResource, 'data.list', [])}
                            count={getProp(historyResource, 'data.count', 0)}
                            isLoading={getProp(historyResource, 'isLoading', false)}
                            showUserAvatar

                            onRowClick={(item) => {
                                this.props.dispatch(showModal('ViewContactCard', {ContactID: item.ContactID}))
                            }}
                            fieldTypes={this.getFieldTypes()}
                        />
                    )}
                </Modal>

                <ModalConfirm
                    title={'Confirm'}
                    show={!!this.state.showConfirm}
                    text={this.state.confirmText}
                    onClose={() => this.handleToggleApproveDialog()}
                    buttonLabel={translate('btn.confirm')}
                    closeButtonLabel={'Cancel'}
                    translate={translate}
                    onConfirm={this.state.approveDialog}
                />

                {(this.state.isOrganizationInfoDialogOpen &&
                    <OrganizationInfoDialog
                        show={this.state.isOrganizationInfoDialogOpen}
                        translate={translate}
                        organizationID={this.state.selectedOrganization?.OrganizationID}
                        handleClose={() => this.setState({
                            isOrganizationInfoDialogOpen: false
                        })}
                    />
                )}

                {(this.state.isContactInfoDialogOpen &&
                    <ContactInfoDialog
                        show={this.state.isContactInfoDialogOpen}
                        translate={translate}
                        contactID={this.state.selectedContact?.ContactID}
                        handleClose={() => this.setState({
                            isContactInfoDialogOpen: false
                        })}
                    />
                )}

            </React.Fragment>
        )
    }
}

export default connect(state => state)(BillsTab)
