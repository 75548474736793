import {StripeIcon} from "../../../../data/themes/icons";
import axios from "axios";
import Env from "../../../../util/env";
import {getJWT} from "../../../util/util-auth";
import {processError} from "../../../../data/services/api-util";
import ModalConfirm from "../modal-confirm";
import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Resources from "../../../../data/services/resources";
import {LoaderLarge} from "../../loader";
import {hideGlobalModal, pushNotification} from "../../../../data/actions/ui";

export default function StripeTerminalModal({translate}) {
    const dispatch = useDispatch();
    const ui = useSelector(state => state.ui);
    const [isLoading, setIsLoading] = useState(false);

    function handleCancelTerminalPaymentIntent() {
        setIsLoading(true);

        axios.delete(
            Env.getApiUrl('api/' + Resources.PaymentsCreateTerminal, {
                IntentUUID: ui?.globalDialogs?.awaitTerminalPaymentIntentModal?.data?.IntentUUID
            }),
            {
                headers: {
                    'Authorization': 'Bearer ' + getJWT().access_token
                }
            }
        )
            .then(() => {
                setIsLoading(false);
                dispatch(hideGlobalModal("awaitTerminalPaymentIntentModal"));
                dispatch(pushNotification({
                    title: translate('text.pay_intent_cancel_success'),
                    timeout: true
                }));
            })
            .catch((error) => {
                setIsLoading(false);
                dispatch(hideGlobalModal("awaitTerminalPaymentIntentModal"))
                console.log(processError(error));
                dispatch(pushNotification({
                    title: translate('text.pay_intent_cancel_failed'),
                    messageType: "error"
                }));
            });
    }

    return (
        <ModalConfirm
            title={'Awaiting Card Insertion'}
            show={!!ui?.globalDialogs?.awaitTerminalPaymentIntentModal?.visible}
            text={`Please insert card to the terminal.`}
            type="custom"
            customIcon={<StripeIcon fill="#fff" className="w-5 h-5"/>}
            customIconBG="bg-[#6772e5]"
            onClose={() => {
                handleCancelTerminalPaymentIntent();
            }}
            closeButtonLabel={'Cancel'}
            translate={translate}
        >
            {isLoading && (
                <LoaderLarge stripesBg={true}/>
            )}
        </ModalConfirm>
    )
}