import {StripeIcon} from "../../../../data/themes/icons";
import React, {useRef, useState} from "react";
import ButtonIcon from "../../button/button-icon";
import Resources from "../../../../data/services/resources";
import {Field} from "../../../../data/services/fields";
import {useDispatch} from "react-redux";
import Badge from "../../badge";
import ResourceTableDialogNew from "../resource-table-dialog-new";
import {getDefaultTableOptions} from "../../../util/util-helpers";
import XCircleIcon from "@heroicons/react/20/solid/XCircleIcon";
import ModalConfirm from "../modal-confirm";
import {deleteDialogResource} from "../../../../data/actions/dialogResource";
import LocalStorage from "../../../../util/localStorage";
import {InformationCircleIcon} from "@heroicons/react/20/solid";
import Tooltip from "../../tooltip";

export default function StripePaymentIntentsModal({translate}) {
    const queryRef = useRef();
    const dispatch = useDispatch();
    const [showModal, setShowModal] = useState(false);
    const [isCancelConfirmModalOpen, setIsCancelConfirmModalOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState({});

    function handleConfirmCancelTerminalPaymentIntent(item) {
        setIsCancelConfirmModalOpen(true);
        setSelectedItem(item);
    }

    function handleCancelTerminalPaymentIntent() {
        dispatch(deleteDialogResource({
            user: LocalStorage.get('user'),
            query: {
                IntentUUID: selectedItem.IntentUUID
            },
            piggyQuery: queryRef?.current ?? {},
            errorMessage: true,
            successMessage: translate(`message.payment_intent_deleted`, [selectedItem.AutoCounter]),
            resource: Resources.PaymentsCreateTerminal,
            piggyResource: Resources.PaymentsTerminal
        }));

        setIsCancelConfirmModalOpen(false);
    }

    function getFields() {
        return {
            AutoCounter: new Field('AutoCounter', '', [], false, 'text', {label: "InvoiceNumber"}),
            Amount: new Field('Amount', '', [], false, 'money'),
            FactorFee: new Field('FactorFee', '', [], false, 'money', {
                label: "StripeFeeAmount", render: (it) => {
                    return "$" + ((it.FactorFee ?? 0) / 100).toFixed(2)
                }
            }),
            NetAmount: new Field('NetAmount', '', [], false, 'money', {
                omitSort: true,
                render: (it) => {
                    return "$" + (((it.Amount * 100) - it.FactorFee) / 100).toFixed(2)
                }
            }),
            IntentUUID: new Field('IntentUUID', '', [], false, 'text'),
            TerminalPaymentStatusID: new Field('TerminalPaymentStatusID', '', [], false, 'custom', {
                render: (it) => { // to do convert to component
                    switch (it.TerminalPaymentStatusID) {
                        case 0:
                            return <Badge type="primary">Created</Badge>
                        case 1:
                            return <Badge type="success">Success</Badge>
                        case 2: {
                            let content = false;

                            try {
                                content = JSON.parse(it?.Result)?.failure_message
                            } catch (e) {
                                console.log(e, it?.Result)
                            }

                            return <Tooltip
                                content={content}
                                disabled={!content}
                            >
                                <span>
                                <Badge type="danger" addClass="items-center gap-2">
                                    Failed

                                    {!!content && (
                                        <InformationCircleIcon className="w-4 h-4 text-white"/>
                                    )}
                                </Badge>
                                    </span>
                            </Tooltip>
                        }
                        case 3:
                            return <Badge type="warning">Canceled</Badge>
                        default:
                            return "Unknown type"
                    }
                }
            }),
            UpdatedByContact: new Field('UpdatedByContact', '', [], false, 'text'),
            CreateDate	: new Field('CreateDate', '', [], false, 'datetime', {omitSort: true}),
        }
    }

    function getQueryFields() {
        return {
            query: new Field('query', '', [''], false, 'search', {
                addContainerClass: "col-span-2",
                hideLabel: true,
                labelType: "float",
                autofocus: true,
            }),
            TerminalPaymentStatusID: new Field('TerminalPaymentStatusID', 0, [''], false, 'select', {
                addContainerClass: "col-span-2",
                labelType: "float",
                isClearable: true
            }),
            offset: new Field('offset', "0", [''], false, 'hidden'),
            limit: new Field("limit", "50", [''], false, 'hidden', {
                hideLabel: true,
                labelType: "float"
            }),
            sort: new Field('sort', "ASC", [''], false, 'hidden'),
            sortBy: new Field('sortBy', "TerminalPaymentIntentID", [''], false, 'hidden'),
        }
    }

    return (
        <>
            <ButtonIcon
                appearance="border"
                tooltip={translate("btn.stripe_terminal")}
                onClick={() => setShowModal(true)}
            >
                <StripeIcon className="w-5 h-5"/>
            </ButtonIcon>

            <ResourceTableDialogNew
                show={showModal}
                resourcePath={Resources.PaymentsTerminal}
                tableKey={'TerminalPaymentIntentID'}

                title={translate("text.stripe_terminal")}
                dispatch={dispatch}
                options={getDefaultTableOptions(getFields(), {}, 'stripe_terminal_state', translate)}
                widthClass={"max-w-full"}
                maxHeightClass="max-h-[calc(100vh-16rem)]"
                sortBy={'TerminalPaymentIntentID'}

                fields={getFields()}
                getQueryFields={getQueryFields()}
                queryRef={queryRef}
                querySelects={{
                    TerminalPaymentStatusID: {
                        0: "Created",
                        1: "Success",
                        2: "Failed",
                        3: "Canceled"
                    }
                }}

                getCustomActions={[
                    {
                        action: (item) => {
                            handleConfirmCancelTerminalPaymentIntent(item);
                        },
                        customContent: () => <XCircleIcon className="w-5 h-5 text-red-600"/>,
                        visible: (item) => item.TerminalPaymentStatusID === 0,
                        title: translate("text.cancel_invoice_intent")
                    }
                ]}

                onClose={() => setShowModal(false)}
                hideDialogFooter={true}
                translate={translate}

                htmlAfter={
                    <ModalConfirm
                        title={`Cancel Pay intent`}
                        show={isCancelConfirmModalOpen}
                        onClose={() => setIsCancelConfirmModalOpen(false)}
                        buttonLabel={translate('btn.cancel_payment_intent')}
                        closeButtonLabel={'Cancel'}
                        translate={translate}
                        onConfirm={handleCancelTerminalPaymentIntent}
                    >
                        <span
                            className="text-base">{`Are you sure you want to cancel payment intent of invoice #${selectedItem?.AutoCounter ?? "(Number missing)"}?`}</span>
                    </ModalConfirm>
                }
            />
        </>
    )
}