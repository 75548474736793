import moment from "moment";
import {evaluate} from "../../common/util/util-vanilla";
import {floatUpTo16InputChange, numberWithCommasInputChange, numberWithCommasToBack} from "../../util/util-formaters";
import {getLookup} from "../../common/util/util-helpers";
import slateValueToHTML from "../../common/components/fields/rich-text-editor/slate-serialize";
import {timePickerValueToServerTime, timeZoneToUTC} from "../../common/util/util-dates";

function isString(obj) {
    return (Object.prototype.toString.call(obj) === '[object String]');
}

const year = moment().format("YYYY")

export class Field {
    constructor(name, value = '', validate = ['empty'], disabled = false, type, metadata, props) {
        this.name = name;
        this.value = value ? value : "";
        this.errorMessage = null;
        this.validate = validate;
        this.isEmpty = !this.value || this.value.length === 0;
        this.disabled = disabled;
        this.type = type;
        this.metadata = metadata;
        this.props = props;
        this.isDirty = false;
    }
}

export class FieldsManager {
    static updateField(fields, name, newValue) {
        let clone = Object.assign({}, fields);
        clone[name].value = FieldsManager.formatValue(newValue, clone[name].validate);
        clone[name].isDirty = true;
        return clone;
    }

    static formatValue(value, validate) {
        if (
            (validate.indexOf('float') > -1)
            || (validate.indexOf('float_or_empty') > -1 || (validate.indexOf('float_not_require') > -1))
        ) {
            return numberWithCommasInputChange(value);
        }
        if (
            (validate.indexOf('float_up_to_12_not_require') > -1)
        ) {
            return floatUpTo16InputChange(value);
        }
        if (
            (validate.indexOf('integer') > -1)
            || (validate.indexOf('integer_or_empty') > -1) || (validate.indexOf('integer_not_require') > -1)
        ) {
            return (!value ? "" : value.toString()).replace(/\D+/g, '');
        }

        return value;
    }

    static updateAndValidateField(fields, name, newValue) {
        let clone = Object.assign({}, fields);
        clone[name].value = FieldsManager.formatValue(newValue, clone[name].validate);
        clone[name] = FieldsManager.validateField(clone[name]);
        return clone;
    }

    static validateFields(fields, list = []) {
        return Object.keys(fields)
            .reduce((memo, key) => {
                if (list.length === 0 || !!~list.indexOf(key)) {
                    memo[key] = FieldsManager.validateField(fields[key], fields);
                } else {
                    memo[key] = fields[key];
                }
                return memo;
            }, {});
    }

    static cleanFields(fields) {
        return Object.keys(fields).map(key => Object.assign({}, fields[key], {value: ""}));
    }

    static validateField(field, allFields) {
        const errorMessage = field.validate.reduce((memo, type) => {
            if (!!type && type.includes("length_")) {
                // Example: "length_===_18", "length_<=_15"...
                let operator = type.split("_")[1]
                let value = type.split("_")[2]
                let condition = `${field.value.length}${operator}${value}`

                if (!evaluate(condition)) {
                    switch (operator) {
                        case "===":
                        case "==":
                            memo.push({
                                key: 'fields.errors.length_eq',
                                values: [value]
                            });
                            break;
                        case"<":
                            memo.push({
                                key: 'fields.errors.length_lt',
                                values: [value]
                            });
                            break;
                        case"<=":
                            memo.push({
                                key: 'fields.errors.length_lte',
                                values: [value]
                            });
                            break;
                        case">":
                            memo.push({
                                key: 'fields.errors.length_mt',
                                values: [value]
                            });
                            break;
                        case">=":
                            memo.push({
                                key: 'fields.errors.length_mte',
                                values: [value]
                            });
                            break;
                        default:
                            return "Error"
                    }
                }
            }

            if (field.value !== '' && !!type && type.includes(":")) {
                const valTypeArr = type.split(":");
                const validationType = valTypeArr[0];
                const validationValue = valTypeArr[1];
                const inputValue = field.value;
                //const isNumber = !isNaN(parseFloat(inputValue)) && isFinite(inputValue);

                // gte Greater Than or Equal
                // lt - Less Than
                // lte - Less Than or Equal
                // max - Length of array or string
                // min

                switch (validationType) {
                    case 'gt': // Greater Than (it can be number, and other field is planned)
                        if (Number(validationValue) >= Number(inputValue)) {
                            memo.push({
                                key: 'fields.errors.greater_than',
                                values: [validationValue]
                            });
                        }
                        break;
                    case 'min':
                        if (Number(validationValue) > Number(inputValue)) {
                            memo.push({
                                key: 'fields.errors.min',
                                values: [validationValue]
                            });
                        }
                        break;
                    case 'max':
                        if (Number(validationValue) < Number(inputValue)) {
                            memo.push({
                                key: 'fields.errors.max',
                                values: [validationValue]
                            });
                        }
                }
            }

            switch (type) {
                case 'required':
                    if (field.value === null || field.value === undefined || field.value === "") {
                        memo.push('fields.errors.required');
                    }
                    break;
                case 'integer_new':
                    if (!Number.isInteger(Number(field.value))) {
                        memo.push('fields.errors.integer');
                    }
                    break;
                case 'non-zero':
                    if (field.value === 0 || field.value === "0") {
                        memo.push('fields.errors.non_zero');
                    }

                    break;
                case 'empty':
                    if (!field.value || (isString(field.value) && field.value.trim() === "")) {
                        memo.push('fields.errors.empty');
                    }
                    break;
                case 'empty_array':
                    if (!field.value || !field.value.length) {
                        memo.push('fields.errors.empty');
                    }
                    break;
                case 'money':
                    if (Math.floor(Number(numberWithCommasToBack(field.value))) > 999999999999) {
                        memo.push('fields.errors.value_too_large');
                    }
                    break;
                case 'empty_select_search':
                    if (!field?.value?.value) {
                        memo.push('fields.errors.empty');
                    }
                    break;
                // @TODO: delete unnecessary cases
                case 'time_custom':
                    if (field.value.includes("_")) {
                        memo.push('fields.errors.time');
                    }
                    break;
                case 'time_custom_empty':
                    if (!field.value || field.value.includes("_")) {
                        memo.push('fields.errors.time');
                    }
                    break;
                case 'email':
                    if (!/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/gi.test(field.value)) {
                        memo.push('fields.errors.email');
                    }
                    break;
                case 'emails': {
                    const value = field?.value.replace(/\s/g, '');
                    // Comma separated emails
                    // eslint-disable-next-line
                    if (!/^(?:(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])(?:,(?=.))?)+$/gi.test(value)) {
                        memo.push('fields.errors.emails');
                    }
                    break;
                }
                case 'email_confirm':
                    if (allFields[field.name.replace("Confirm", "")].value !== field.value) {
                        memo.push('fields.errors.emails_dont_match');
                    }
                    break;
                case 'email_or_empty':
                    if ((field.value !== "") && !/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/gi.test(field.value.trim())) {
                        memo.push('fields.errors.email');
                    }
                    break;
                case 'password':
                    if (!/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d!@#\$%\^&\*]{8,}$/g.test(field.value)) {
                        memo.push('fields.errors.password');
                    }
                    break;
                case 'pin_four_digits':
                    if (!/^\d{4}$/g.test(field.value)) {
                        memo.push('fields.errors.pin_four_digits');
                    }
                    break;
                case 'date':
                    if (!field.value) {
                        // Just pass, empty is fine
                    } else if (!FieldsManager.checkDateFormat(field.value)) {
                        memo.push('fields.error.date_format');
                    }
                    break;
                case 'future_date':
                    if (!field.value) {
                        // Just pass, empty is fine
                    } else if (!FieldsManager.checkDateFormat(field.value)) {
                        memo.push('fields.error.date_format');
                    } else if (!FieldsManager.checkDateDigits(field.value)) {
                        memo.push('fields.error.date_format_digits');
                    } else if (!FieldsManager.isDateInTheFuture(field.value)) {
                        memo.push('fields.error.outdated_date');
                    }
                    break;
                case 'federal_id':
                    if (field.value.replace(/[\_-]+/g, "").length < 9) {
                        memo.push('fields.errors.federal_id');
                    }
                    break;
                case 'contains_number': {
                    let regex = /\d/g;
                    if (!regex.test(field.value)) {
                        memo.push('fields.errors.contains_number');
                    }
                    break;
                }
                case 'integer_or_empty':
                    if ((field.value !== "") && !isNaN(field.value) && !/^[0-9]+$/g.test(field.value)) {
                        memo.push('fields.errors.integer');
                    }
                    break;
                case 'integer':
                    if (!/^[0-9]+$/g.test(field.value)) {
                        memo.push('fields.errors.integer');
                    }
                    break;
                case 'integer_up_to_100':
                    // eslint-disable-next-line
                    if (((field.value !== "") && (!/^[0-9]+$/g.test(field.value))) || (field.value > 100)) {
                        memo.push('fields.errors.integer_up_to_100');
                    }
                    break;
                case 'integer_up_to_256':
                    // eslint-disable-next-line
                    if ((field.value !== "") && (!/^[0-9]+$/g.test(field.value)) || (field.value > 256)) {
                        memo.push('fields.errors.integer_up_to_256');
                    }
                    break;
                case 'string_up_to_100':
                    if (field.value.length > 100) {
                        memo.push('fields.errors.string_up_to_100');
                    }
                    break;
                case 'string_up_to_256':
                    if (field.value.length > 256) {
                        memo.push('fields.errors.string_up_to_256');
                    }
                    break;
                case 'float_or_empty':
                    if (!(field.value !== "" || !field.value) && !/^[0-9,.]*$/g.test(field.value)) {
                        memo.push('fields.errors.float');
                    }
                    break;
                case 'float':
                    if (!/\d+(\.\d+)?/g.test(field.value)) {
                        memo.push('fields.errors.float');
                    }
                    break;
                case 'float_up_to_100':
                    if ((field.value !== "") && !/\d+(\.\d+)?/g.test(field.value)) {
                        memo.push('fields.errors.float_up_to_100');
                    } else if ((field.value !== "") && parseFloat(field.value) > 100) {
                        memo.push('fields.errors.float_up_to_100');
                    }
                    break;
                case 'year':
                    if (field.value < 0 || field.value > (year)) {
                        memo.push('fields.errors.wrong_year');
                    }
                    break;
                case 'bigger_than_0':
                    if (field.value < 0) {
                        memo.push('fields.errors.number_less_than_0');
                    }
                    break;
                case 'bigger_than_1':
                    if (field.value < 1) {
                        memo.push('fields.errors.number_less_than_1');
                    }
                    break;
                case 'integer_not_require':
                    if (field.value && !/^(\s*|\d+)$/g.test(field.value)) {
                        memo.push('fields.errors.integer');
                    }
                    break;
                case 'float_not_require':
                    if (!/^((\d+\.?|\.(?=\d))?\d{0,9})$/g.test(field.value)) {
                        memo.push('fields.errors.float');
                    }
                    break;
                case 'five_char_max':
                    if (field.value.length > 5) {
                        memo.push('fields.errors.five_char_max');
                    }
                    break;
                default:
                    break;
            }
            return memo;
        }, []);
        field.isEmpty = !field || !field.value || field.value.length === 0;

        return Object.assign({}, field, {
            errorMessage: errorMessage.length ? errorMessage : null
        });
    }

    static checkFieldsForErrors(fields, list = []) {
        return Object.keys(fields)
            .reduce((memo, key) => {
                if (list.length === 0 || !!~list.indexOf(key)) {
                    return memo && (!fields[key].errorMessage);
                } else {
                    return memo;
                }
            }, true);
    }

    static resetFieldsErrors(fields) {
        return Object.keys(fields)
            .reduce((memo, key) => {
                fields[key].errorMessage = "";
                memo[key] = fields[key];
                return memo;
            }, {});
    }

    static disableFields(fields, list = []) {
        return Object.keys(fields)
            .reduce((memo, key) => {
                if (list.length === 0 || !!~list.indexOf(key)) {
                    fields[key].disabled = true;
                    memo[key] = fields[key];
                } else {
                    memo[key] = fields[key];
                }
                return memo;
            }, {});
    }

    static enableFields(fields, list = []) {
        return Object.keys(fields)
            .reduce((memo, key) => {
                if (list.length === 0 || !!~list.indexOf(key)) {
                    fields[key].disabled = false;
                    memo[key] = fields[key];
                } else {
                    memo[key] = fields[key];
                }
                return memo;
            }, {});
    }

    static doNotRequireField(fields, name) {
        let clone = Object.assign({}, fields);

        clone[name].validate = clone[name].type !== "float" ? [''] : ['float_or_empty']
        clone[name].errorMessage = "";
        return clone;
    }

    static requireField(fields, name) {
        let clone = Object.assign({}, fields);
        clone[name].validate = FieldsManager.getValidationFromType(clone[name].type);
        return clone;
    }

    static getValidationFromType(type) {
        switch (type) {
            case "select-search":
            case "creatable-select-search":
                return ["empty_select_search"]
            case "float":
                return ["float"]
            default:
                return ["empty"]
        }
    }

    static checkDateFormat(dateString) {
        // First check for the pattern
        return /^\d{1,2} \/ \d{4}$/.test(dateString);
    }

    static checkDateDigits(dateString) {
        // Parse the date parts to integers
        const parts = dateString.split('/');
        const month = parseInt(parts[0].trim(), 10);
        const year = parseInt((parts[1].trim()), 10);

        // Check the ranges of month and year
        return !(year < 1000 || year > 3000 || month === 0 || month > 12);
    }

    static isDateInTheFuture(dateString) {
        const expDate = moment(dateString, 'MM / YYYY').toDate();
        const now = moment(moment().format('MM / YYYY'), 'MM / YYYY').toDate();
        return !((expDate - now) < 0);
    }

    static getFieldKeyValues(fields) {
        if (!fields) return;

        return Object.keys(fields)
            .reduce((memo, key) => {

                switch (fields[key].type) {
                    case 'multi-select-search':
                    case 'multi-select':
                        memo[key] = Array.isArray(fields[key].value) ? fields[key].value?.map(it => it.value) : []
                        break;
                    case 'select-search':
                    case 'select-search-clearable':
                        if (fields[key]?.props?.multi || fields[key]?.props?.isMulti) {
                            memo[key] = Array.isArray(fields[key].value) ? fields[key].value?.map(it => it.value) : []
                        } else {
                            memo[key] = fields[key]?.value?.value ?? ''
                        }
                        break;
                    case 'creatable-csv': {
                        if (!fields[key]?.value) {
                            memo[key] = "";
                        } else if (typeof fields[key]?.value === "string") {
                            memo[key] = fields[key].value
                        } else {
                            memo[key] = fields[key].value.map((it) => {
                                it = it.value;
                                return it;
                            }).join(",")
                        }
                        break;
                    }
                    case 'creatable-select-search':
                        if (fields[key]?.props?.multi || fields[key]?.props?.isMulti) {
                            memo[key] = Array.isArray(fields[key].value) ? fields[key].value : []
                        } else {
                            memo[key] = fields[key]?.value ?? ''
                        }
                        break;
                    case 'checkbox':
                    case 'switch':
                        memo[key] = fields[key].value ? 1 : 0;
                        break;
                    case 'date':
                        memo[key] = fields[key].value ? moment(fields[key].value).format("YYYY-MM-DD HH:mm:ss") : ''
                        break;
                    case 'float_or_empty':
                        memo[key] = numberWithCommasToBack(fields[key].value) ?? ''
                        break;
                    case 'float':
                    case 'money':
                        memo[key] = numberWithCommasToBack(fields[key].value) ?? 0
                        break;
                    case 'datetime': {
                        let date = fields[key].value
                        let time = timePickerValueToServerTime(fields[`${key}Time`]?.value ?? "00:00:00")
                        if (date && time && time != "Invalid date") {
                            date = date.split(" ")[0] + " " + time
                        }
                        if (moment(date).format("YYYY-MM-DD HH:mm:ss") == "Invalid date") {
                            memo[key] = null
                            break
                        }
                        memo[key] = moment(date).format("YYYY-MM-DD HH:mm:ss")
                        break;
                    }
                    case 'color-picker':
                        memo[key] = fields[key].value?.hex ?? "#FFFFFF";
                        break;
                    case 'datetimezone': {

                        let date = fields[key].value;

                        let time = timePickerValueToServerTime(fields[`${key}Time`]?.value ?? "00:00:00")

                        if (date && time && time !== "Invalid date") {
                            date = date.split(" ")[0] + " " + time
                        }

                        memo[key] = timeZoneToUTC(date);
                        break;
                    }
                    case 'rich-text':
                        memo[key] = slateValueToHTML(fields[key].value);
                        break
                    case 'meta':
                        return memo;
                    default:
                        if (typeof fields[key].value === 'string') {
                            memo[key] = fields[key].value.trim();
                        } else {
                            memo[key] = fields[key].value;
                        }
                        break;
                }

                return memo;
            }, {});
    }

    static getFieldKeyValuesAndLabels(fields, selects = {}) {
        // Generates additional key-label pairs out of select-search fields
        const fieldKeyValues = this.getFieldKeyValues(fields);

        return Object.keys(fieldKeyValues).reduce((memo, key) => {
            if (key.slice(-2) === "ID") {
                if (fields[key].type === "select-search" || fields[key].type === "creatable-select-search") {
                    memo[key] = fields[key]?.value?.value ?? '';
                    memo[key.replace("ID", "")] = fields[key]?.value?.label ?? null;
                } else {
                    memo[key] = fields[key]?.value;
                    if (selects[key]) {
                        memo[key.replace("ID", "")] = selects[key][fields[key]?.value] ?? null;
                    } else {
                        memo[key.replace("ID", "")] = getLookup(key.replace("ID", "")) ?? null;
                    }
                }
            } else {
                memo[key] = fieldKeyValues[key];
            }

            return memo;
        }, {});
    }

    static getFieldSelectValues(fields, selects = {}) {
        if (!fields) {
            return;
        }

        return Object.keys(fields)
            .reduce((memo, key) => {
                let keyUpdate = key.replace("ID", "");

                switch (fields[key].type) {
                    case 'select-search':
                        memo[keyUpdate] = fields[key]?.value?.label ?? ''
                        break;
                    case 'select':
                        if (selects[key]) {
                            memo[keyUpdate] = selects[key][fields[key]?.value];
                        } else {
                            memo[keyUpdate] = getLookup(keyUpdate)[fields[key]?.value];
                        }
                        break;
                    default:
                        return memo;
                }

                return memo;
            }, {});
    }

    static setFieldsToReadOnly(fieldTemplates) {
        return Object.values(fieldTemplates).reduce((memo, it) => {
            if (!it.props) {
                it.props = {}
            }

            switch (it.type) {
                case 'google-locations' :
                    it.type = 'hidden';
                    break;
                case 'select':
                case 'select-search':
                case 'checkbox':
                case 'time-custom':
                case 'datetime':
                    it.disabled = true;
                    break;
                default:
                    it.props.readOnly = true;
            }

            memo[it.name] = it;
            return memo;
        }, {});
    }
}
