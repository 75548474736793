import React from "react";
import {classNames} from "../../../../common/util/util-helpers";
import PlusCircleIcon from "@heroicons/react/24/outline/PlusCircleIcon";
import ChevronRightIcon from "@heroicons/react/24/outline/ChevronRightIcon";
import {BuildingOfficeIcon, ExclamationTriangleIcon} from "@heroicons/react/20/solid";
import {openInNewTab} from '../../../../common/util/util-helpers'
import TruckIcon from "@heroicons/react/20/solid/TruckIcon";
import {CreditCardIcon} from "@heroicons/react/24/outline";
import InfoBar from "../../../../common/components/info-paragraph/info-bar";
import Subtitle from "../../../../common/components/layout/layout-components/page/subtitle";
import {Loader, LoaderLarge} from "../../../../common/components/loader";
import Badge from "../../../../common/components/badge";

export default function LoadInfoBasic({
                                          fields,
                                          hasErrors,
                                          warnings,
                                          translate,
                                          CustomerLoadSetting,
                                          loadStatusID,
                                          loadStatus,
                                          onAddNoteClick,
                                          areInternalNotesVisible,
                                          areCustomerNotesVisible,
                                          isSectionExpanded,
                                          toggleExpandSection,
                                          getCustomerPrimaryContact,
                                          customerContactsList,
                                          isLoading,
                                          innerRef,
                                          Price,
                                          hideWarningMessages,
                                          duplicateCustomerReferenceNumberLoad
                                      }) {
    if (Array.isArray(CustomerLoadSetting)) {
        CustomerLoadSetting = CustomerLoadSetting.reduce((memo, it) => {
            memo[it.Type] = it;
            return memo
        }, {});
    }

    const hasPrice = Price && Price !== '0.00';

    return (
        <React.Fragment>
            <div ref={innerRef} className="grid grid-cols-12 px-6 relative py-3">
                <div className="col-span-5 py-3 pr-3">
                    <div className="flex items-center">
                        <button
                            className="btn btn-icon -ml-2 md mr-2 w-8 h-8"
                            onClick={() => toggleExpandSection("LoadInfoBasic")}
                        >
                            <ChevronRightIcon
                                className={
                                    classNames(
                                        "w-5 h-5",
                                        isSectionExpanded ? "rotate-90" : undefined
                                    )
                                }
                            />
                        </button>

                        <Subtitle subtitle={translate("text.basic_load_info")}/>
                    </div>

                    {isSectionExpanded && (
                        <React.Fragment>
                            <div className="mt-3 space-y-2">
                                {hasErrors && (
                                    <InfoBar
                                        type="danger"
                                        Icon={ExclamationTriangleIcon}
                                        message={translate("text.fill_required_fields")}
                                    />
                                )}

                                {!!warnings?.isCustomerBlacklisted && (
                                    <InfoBar
                                        Icon={BuildingOfficeIcon}
                                        type="danger"
                                        message={translate(
                                            "text.customer_is_blacklisted"
                                        )}
                                        onClick={() => openInNewTab('/customers/info/' + warnings?.OrganizationID + "/" + warnings?.CustomerID + "?tab=customers/credit")}
                                    />
                                )}

                                {!!warnings?.duplicateRefNumber && (
                                    <InfoBar
                                        Icon={TruckIcon}
                                        type="warning"
                                        message={translate(
                                            "text.duplicate_reference_number",
                                        )}
                                        onClick={() => openInNewTab('/loads/info/' + warnings.DuplicateLoadID)}
                                    />
                                )}

                                {!!warnings?.isOverCreditLimit && !warnings?.allowOverLimit && hasPrice && (
                                    <InfoBar
                                        Icon={CreditCardIcon}
                                        type="danger"
                                        message={translate(
                                            "text.customer_is_over_credit_limit_price"
                                        )}
                                        onClick={() => openInNewTab('/customers/info/' + warnings?.OrganizationID + "/" + warnings?.CustomerID + "?tab=customers/credit")}
                                    />
                                )}

                                {!!warnings?.isOverCreditLimit && !warnings?.allowOverLimit && !hasPrice && (
                                    <InfoBar
                                        Icon={CreditCardIcon}
                                        type="danger"
                                        message={translate(
                                            "text.customer_is_over_credit_limit"
                                        )}
                                        onClick={() => openInNewTab('/customers/info/' + warnings?.OrganizationID + "/" + warnings?.CustomerID + "?tab=customers/credit")}
                                    />
                                )}


                                {!!warnings?.isOverCreditLimit && !!warnings?.allowOverLimit && (
                                    <InfoBar
                                        Icon={CreditCardIcon}
                                        type="warning"
                                        message={translate(
                                            "text.customer_is_over_credit_limit_but_allowed"
                                        )}
                                        onClick={() => openInNewTab('/customers/info/' + warnings?.OrganizationID + "/" + warnings?.CustomerID + "?tab=customers/credit")}
                                    />
                                )}

                                {!areCustomerNotesVisible && (
                                    <div>
                                        <button
                                            className="btn flex gap-2 text-xs py-1 text-tm-gray-700 hover:text-primary"
                                            onClick={() => onAddNoteClick("ExternalNotesCustomer")}
                                        >
                                            <PlusCircleIcon className="w-5 h-5"/>
                                            {translate("btn.customer_notes")}
                                        </button>
                                    </div>
                                )}

                                {!areInternalNotesVisible && (
                                    <div>
                                        <button
                                            className="btn flex gap-2 text-xs py-1 text-tm-gray-700 hover:text-primary"
                                            onClick={() => onAddNoteClick("InternalNotes")}
                                        >
                                            <PlusCircleIcon className="w-5 h-5"/>
                                            {translate("btn.internal_notes")}
                                        </button>
                                    </div>
                                )}

                                {getCustomerPrimaryContact(customerContactsList)}
                            </div>

                            {!!CustomerLoadSetting && (
                                <div className="mt-6">
                                    {!!CustomerLoadSetting[0]?.Instruction && !!CustomerLoadSetting[0]?.Active && (
                                        <div className="mb-3">
                                            <h5 className="text-sm font-semibold text-tm-gray-600">{translate('text.general_customer_notes')}</h5>

                                            <InfoBar>
                                                <span
                                                    dangerouslySetInnerHTML={{__html: CustomerLoadSetting[0].Instruction.split("\n").join("<br/>")}}
                                                >
                                                </span>
                                            </InfoBar>
                                        </div>
                                    )}

                                    {!!loadStatusID && !!CustomerLoadSetting[loadStatus]?.Instruction && !!CustomerLoadSetting[loadStatus]?.Active && (
                                        <React.Fragment>
                                            <h5 className="text-sm font-semibold text-tm-gray-600">
                                                <span
                                                    className="text-primary">{CustomerLoadSetting[loadStatus]?.Type}</span> {translate("text.status_notes")}
                                            </h5>

                                            <InfoBar>
                                                <span
                                                    dangerouslySetInnerHTML={{__html: CustomerLoadSetting[loadStatus]?.Instruction.split("\n").join("<br/>")}}
                                                >
                                                </span>
                                            </InfoBar>
                                        </React.Fragment>
                                    )}
                                </div>
                            )}
                        </React.Fragment>
                    )}
                </div>

                {isSectionExpanded && (
                    <div className="pl-5 col-span-7 py-3">
                        <div className="grid grid-cols-12 gap-4">
                            {fields}

                            {!!duplicateCustomerReferenceNumberLoad?.LoadID?.length && !hideWarningMessages && (
                                <InfoBar
                                    type="warning"
                                    addClass="col-span-full"
                                >
                                    {translate("text.CustomerReferenceNumberDuplicate")}

                                    {duplicateCustomerReferenceNumberLoad.LoadID.map((LoadID, index) => {
                                        return <Badge addClass="items-center" type="warning" onClick={() => openInNewTab('/loads/info/' + LoadID)}>
                                            {duplicateCustomerReferenceNumberLoad.LoadNumber[index]}
                                        </Badge>
                                    })}
                                </InfoBar>
                            )}
                        </div>
                    </div>
                )}

                {isLoading && (
                    <React.Fragment>
                        {isSectionExpanded && (
                            <LoaderLarge stripesBg={true}/>
                        )}

                        {!isSectionExpanded && (
                            <Loader stripesBg={true}/>
                        )}
                    </React.Fragment>
                )}
            </div>
        </React.Fragment>
    )
}