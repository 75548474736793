import React, {useEffect, useRef, useState} from "react";
import NotificationsContainer from "./notifications-container";
import {useSelector} from "react-redux";
import {useHistory} from "react-router";
import {hideGlobalModal, pushNotification, showGlobalModal} from "../../../data/actions/ui";

export default function Notifications({translate, dispatch, generateNotificationBaseOnTitle}) {
    let currentID = useRef(0);
    const history = useHistory();
    const ui = useSelector((state) => state.ui);
    const [notification, setNotification] = useState({});
    const [closeNotification, setCloseNotification] = useState({});
    const [clearNotifications, setClearNotifications] = useState(0);

    useEffect(() => {
        const newNotification = ui.notification;

        if (newNotification && ui.notification.position !== 'silent') {
            currentID.current = currentID.current + 1;

            setNotification(Object.assign(
                // Defaults parameters
                {
                    id: currentID.current,
                    isOpen: true,
                    isCurrent: true,
                    position: "bottom-left",
                    messageType: "success",
                    notificationType: "simple"
                },

                // Notification with parameters (overrides defaults)
                newNotification,

                //Handle defaults/exceptions
                !!generateNotificationBaseOnTitle && generateNotificationBaseOnTitle(ui.notification, dispatch, translate, history),
                !generateNotificationBaseOnTitle && {title: ui.notification.title}
            ));

            if (ui.notification?.notificationMetadata?.type) {
                switch (newNotification.notificationMetadata.type) {
                    case 'PAYMENT_INTENT_STATUS_CREATED':
                        dispatch(hideGlobalModal('awaitTerminalPaymentIntentModal'));
                        break;
                    default:
                        console.log("No or incorrect notification type specified.")
                }
            }
        } else if (newNotification && ui.notification.position === 'silent') {
            switch (newNotification.notificationMetadata.type) {
                case 'AWAIT_TERMINAL_PAYMENT_INTENT':
                    dispatch(showGlobalModal('awaitTerminalPaymentIntentModal', newNotification?.notificationMetadata));
                    break;
                case 'PAYMENT_INTENT_STATUS_FAILED':
                    dispatch(hideGlobalModal('awaitTerminalPaymentIntentModal'));
                    dispatch(showGlobalModal('StripePaymentErrorModal', {
                        content: newNotification?.content,
                        metadata: newNotification?.notificationMetadata
                    }));
                    break;
                default:
                    console.log("No or incorrect type specified.")
            }
        }
    }, [ui.notification])

    useEffect(() => {
        if (ui.closeNotification) {
            setCloseNotification(ui.closeNotification);
        }
    }, [ui.closeNotification]);

    useEffect(() => {
        if (!Object.keys(ui).length) {
            setClearNotifications(clearNotifications + 1);
        }
    }, [ui]);

    return (
        <div
            aria-live="assertive"
            className="pointer-events-none fixed inset-0 flex px-4 py-6 sm:p-6 z-[100]"
        >
            <NotificationsContainer
                position="top"
                notification={notification}
                closeNotification={closeNotification}
                clearNotifications={clearNotifications}
            />

            <NotificationsContainer
                position="bottom-left"
                notification={notification}
                closeNotification={closeNotification}
                clearNotifications={clearNotifications}
            />

            <NotificationsContainer
                position="top-right"
                notification={notification}
                closeNotification={closeNotification}
                clearNotifications={clearNotifications}
            />

            <NotificationsContainer
                position="top-left"
                notification={notification}
                closeNotification={closeNotification}
                clearNotifications={clearNotifications}
            />

            <NotificationsContainer
                position="bottom"
                notification={notification}
                closeNotification={closeNotification}
                clearNotifications={clearNotifications}
            />

            <NotificationsContainer
                position="bottom-right"
                notification={notification}
                closeNotification={closeNotification}
                clearNotifications={clearNotifications}
            />
        </div>
    )
}
