import React, {Component, useState} from 'react'
import {connect} from 'react-redux'
import {Field, FieldsManager} from '../../../data/services/fields'
import LocalStorage from '../../../util/localStorage'
import Resources from '../../../data/services/resources'
import axios from 'axios'
import Env from '../../../util/env'
import {processResponse} from '../../../data/services/api-util'
import Dropzone from 'react-dropzone'
import moment from 'moment'
import {getSecondResource} from '../../../data/actions/secondResource'
import FileList from "../../../common/components/dropzone/file-list";
import {
    ACCOUNT_CREDIT_CARD,
    ACCOUNT_TYPE_CASH,
    ACCOUNT_TYPE_OTHER_CURRENT_ASSETS,
    DEFAULT_METADATA_SELECT_SEARCH_QUERY,
    FIXED_ASSET_TYPES,
    MAJOR_ACCOUNT_TYPE_EXPENSE,
    MAJOR_ACCOUNT_TYPE_LIABILITY,
    MAXIMUM_DOCUMENT_UPLOAD_SIZE,
    PAYMENT_METHOD_CACHE,
    PAYMENT_METHOD_CARD,
    PAYMENT_METHOD_CHECK,
    PAYMENT_METHOD_DEBIT,
    PAYMENT_METHOD_FACTOR, PAYMENT_METHOD_PAYMENT_PROCESSOR,
    READ_PERM,
    VIN_CODE_FIELD_LENGTH
} from '../../../util/util-constants'
import {
    checkPerm,
    classNames,
    getDefaultContactGroup,
    getDefaultUserOfficeValues,
    getLookup,
    getProp,
    returnSameKeyValueObject
} from '../../../common/util/util-helpers'
import {cloneDeep, dirtyClone, toggleBodyScroll} from '../../../common/util/util-vanilla'
import {numberWithCommas, numberWithCommasToBack} from '../../../util/util-formaters'
import AccountingLoadBreakdown from '../accounting-components/accounting-load-breakdown'
import AccountingMonthlyBreakdown from '../accounting-components/accounting-monthly-breakdown'
import InvoiceExpenseItemTable from './invoice-expense-item-table'
import InvoiceIncomeItemTable from './invoice-income-item-table'
import {EyeIcon, InformationCircleIcon, PencilIcon} from '@heroicons/react/24/outline'
import GroupsViewDialog from '../../my-company/groups-view/groups-view-dialog'
import {showModal} from '../../../data/actions/ui'
import {
    ArrowTrendingDownIcon,
    ArrowTrendingUpIcon,
    DocumentMinusIcon,
    ReceiptPercentIcon
} from "@heroicons/react/20/solid";
import {toFrontDateTime} from "../../../common/util/util-dates";
import PlusIcon from "@heroicons/react/20/solid/PlusIcon";
import XMarkIcon from "@heroicons/react/20/solid/XMarkIcon";
import {fieldsToHtml, fillFieldsFromData} from "../../../common/util/util-fields";
import FieldText from "../../../common/components/fields/field-text";
import FieldDropdownSelect from "../../../common/components/fields/field-dropdown-select";
import FieldOptions from "../../../common/components/fields/field-options";
import {LoaderSmall} from "../../../common/components/loader";
import ModalDefault from "../../../common/components/modal/modal-default";
import ButtonsGroup from "../../../common/components/buttons/buttons-group";
import FieldDate from "../../../common/components/fields/field-date";
import FieldSelectSearch from "../../../common/components/fields/field-select-search";
import FieldContainer from "../../../common/components/fields/field-container";
import FieldSwitch from "../../../common/components/fields/field-switch";
import InfoBar from "../../../common/components/info-paragraph/info-bar";
import FieldTextarea from "../../../common/components/fields/field-textarea";
import OrganizationInfoDialog from "../../../common/components/modal/modal-global/organization-info-dialog";
import ModalSaveResource from "../../../common/components/modal/modal-save-resource";
import ProductServiceInformationDialog from "../../../common/components/modal/product-service-info-dialog";
import Subtitle from "../../../common/components/layout/layout-components/page/subtitle";
import {getJWT} from "../../../common/util/util-auth";
import FileViewer from "../../../common/components/file-viewer/components";
import FieldCellTextNew from "../../../common/components/fields/field-cell-text-new";
import FieldCellSelectSearch from "../../../common/components/fields/field-cell-select-search";
import FieldCellMoney from "../../../common/components/fields/field-cell-money";
import PencilAltOutlineIcon from "@heroicons/react/24/outline/PencilSquareIcon";
import InventoryReportDialog from "../../../common/components/modal/inventory-report-dialog";
import Button from "../../../common/components/button";

class InvoiceDialog extends Component {

    constructor(props) {
        super(props)

        this.state = {
            fields: this.getFields(),
            notePayableFields: this.getNotePayableFields(),
            items: [],
            rawItems: [],
            files: [],
            invoiceCreated: false,
            dialogType: this.props.dialogType ?? this.getDialogType(),
            isProductServiceInfoDialogVisible: false,
            ProductServiceInfoName: '',
            ProductServiceInfoIndex: '',
            isInvoiceDialogHidden: false,
            dialogHideAnimation: false,
            QueryAccountTypeID: ACCOUNT_TYPE_CASH,
            filePreview: false,
            attached: false,
            edit: {},
            isGroupInfoDialogOpen: false,
            groupInfoData: {},
            isChargeToModalOpen: false,
            isOrganizationInfoDialogOpen: false,
            ErrorMessage: undefined,
            isDirty: false,
            selectedReportItem: undefined,
            reportModalOpen: false
        }

        this.TotalAmount = 0
        this.escFunction = this.escFunction.bind(this)

        this.addBtnRef = React.createRef();

        this.selects = {
            LoanOrganizationID: {
                api: 'api/' + Resources.OrganizationsQuick,
                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                searchMap: (item) => ({
                    value: item.OrganizationID,
                    label: item.LegalName
                })
            },
            LiabilityAccountID: {
                api: 'api/' + Resources.AccountsQuick,
                query: {MainAccountTypeID: MAJOR_ACCOUNT_TYPE_LIABILITY, NotePayable: 1},
                searchMap: (it) => {
                    return {
                        label: it.AccountName,
                        value: it.AccountID
                    }
                }
            },
            InterestExpenseAccountID: {
                api: 'api/' + Resources.AccountsQuick,
                query: {MainAccountTypeID: MAJOR_ACCOUNT_TYPE_EXPENSE},
                searchMap: (it) => {
                    return {
                        label: it.AccountName,
                        value: it.AccountID
                    }
                }
            },
        }
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        if (this.props.updateItem) {
            this.fetchData()
        }

        if (this.props.isCopy) {
            this.setState({isDirty: true})
        }

        toggleBodyScroll()
        document.addEventListener('keydown', this.escFunction, false)
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.resource !== prevProps.resource && !!this.props.resource.create && !!this.props.resource.create.id) {
            let items = this.state.items
            items[this.state.ProductServiceInfoIndex] = FieldsManager.updateField(items[this.state.ProductServiceInfoIndex], 'ProductServiceID', {
                label: this.state.createdProductServiceName,
                value: this.props.resource.create.id
            })

            this.setState({
                invoiceCreated: true,
                items: items
            })
        }

        if (prevProps.secondResource.isLoading && !this.props.secondResource.isLoading) {
            this.setState({
                fields: this.getFields(getProp(this.props, 'secondResource.data', {})),
                items: getProp(this.props, 'secondResource.data.Lines', [])
                    .map(listItem => {
                        return this.getExpenseItemField(listItem)
                    }),
            })
        }
    }

    componentWillUnmount() {
        toggleBodyScroll()
        document.removeEventListener('keydown', this.escFunction, false)
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getSecondResource({
            user: LocalStorage.get('user'),
            query: {
                id: this.props.updateItem.InvoiceID ?? this.props.updateItem.ExpenseID
            },
            resource: this.getDialogResource(),
        }))
    }

    getFields = (item = null) => {
        if (item?.ContactID) {
            item.Contact = item.Contact.FirstName + ' ' + item.Contact.LastName
        }
        if (item) {
            item.ChargeTo = item?.ContactID ? "1" : "2"
        }

        let fieldTemplates = {
            ChargeTo: new Field('ChargeTo', "2", [''], !!item?.OfficeID && !this.props.isCopy, 'button-group'),
            ContactID: new Field('ContactID', '', [item?.ChargeTo == 1 ? 'empty' : ''], !!item?.OfficeID && !this.props.isCopy, 'select-search'),
            OrganizationID: new Field('OrganizationID', '', [item?.ChargeTo != 1 ? 'empty' : ''], !!item?.OfficeID && !this.props.isCopy, 'select-search'),
            OfficeID: new Field('OfficeID', getDefaultUserOfficeValues(), ['empty'], false, 'select-search'),
            ContactGroupID: new Field(
                'ContactGroupID',
                getDefaultContactGroup(),
                ['empty'],
                false,
                'select-search',
                {
                    addContainerClass: 'col-span-6 3xl:col-span-6',
                    label: 'ExpenseGroupID'
                },
            ),
            PaymentTypeID: new Field('PaymentTypeID', '', ['empty'], false, 'select'),
            CheckNumber: new Field('CheckNumber', '', [''], false, 'hidden', {addContainerClass: 'col-span-3'}),
            Date: new Field('Date', moment().format('YYYY-MM-DD HH:mm:ss'), ['empty']),
            AccountID: new Field('AccountID', '', ['empty'], false, 'select-search'),
            BookDate: new Field('BookDate', moment().format('YYYY-MM-DD HH:mm:ss'), ['empty']),
            DueDate: new Field('DueDate', moment().add(this.getDefaultNetTerm(), 'days').format('YYYY-MM-DD HH:mm:ss'), ['empty']),
            InternalNotes: new Field('InternalNotes', '', [''], false, item?.InternalNotes ? "textarea" : "hidden"),
            ExternalNotes: new Field('ExternalNotes', '', [''], false, item?.ExternalNotes ? "textarea" : "hidden"),
            RefNumber: new Field('RefNumber', '', ['empty'], false, 'text'),
            Description: new Field('Description', '', [''])
        }

        return item ? fillFieldsFromData(fieldTemplates, item) : fieldTemplates
    }

    getNotePayableFields = (item = {}) => {
        const IsDelayedPayment = false

        let fieldTemplates = {
            IsDelayedPayment: new Field('IsDelayedPayment', '', [''], false, 'checkbox', {addContainerClass: 'sm:col-span-4 relative z-0'}),
            StartDate: new Field('StartDate', moment().format('YYYY-MM-DD HH:mm:ss'), [''], false, IsDelayedPayment ? 'date' : 'hidden', {addContainerClass: 'col-span-full sm:col-start-1 sm:col-span-3'}),
            DownPayment: new Field('DownPayment', '', [''], false, IsDelayedPayment ? 'money' : 'hidden', {addContainerClass: 'col-span-full sm:col-span-3'}),
            InterestRate: new Field('InterestRate', '', [''], false, IsDelayedPayment ? 'float' : 'hidden', {
                addContainerClass: 'col-span-full sm:col-span-3',
                htmlAfterField: () => <span className="absolute right-3 leading-10 font-bold text-tm-gray-500">%</span>
            }),
            LoanTerm: new Field('LoanTerm', '', ['integer'], false, IsDelayedPayment ? 'text' : 'hidden', {addContainerClass: 'col-span-full sm:col-span-3'}),

            LoanOrganizationID: new Field('LoanOrganizationID', '', [''], false, IsDelayedPayment ? 'select-search' : 'hidden', {addContainerClass: 'sm:col-span-4'}),

            LiabilityAccountID: new Field('LiabilityAccountID', '', [''], false, IsDelayedPayment ? 'select-search' : 'hidden', {addContainerClass: 'sm:col-span-4'}),
            InterestExpenseAccountID: new Field('InterestExpenseAccountID', '', [''], false, IsDelayedPayment ? 'select-search' : 'hidden', {addContainerClass: 'sm:col-span-4'}),
        }

        return fillFieldsFromData(fieldTemplates, item)
    }

    handleToggleOrganizationInfoDialog = (organizationData) => {
        this.setState({
            selectedOrganization: organizationData,
            isOrganizationInfoDialogOpen: true
        })
    }

    handleOrganizationQuickView = (it) => {
        if (it.value.Organization.CustomerID) {
            this.props.dispatch(showModal('ViewCustomerCard', {
                CustomerID: it?.value?.Organization?.CustomerID,
                OrganizationID: it?.value?.Organization?.OrganizationID
            }))
        } else if (it.value.Organization.VendorID) {
            this.props.dispatch(showModal('ViewVendorCard', {
                VendorID: it?.value?.Organization?.VendorID,
                OrganizationID: it?.value?.Organization?.OrganizationID
            }))
        } else if (it.value.Organization.CarrierID) {
            this.props.dispatch(showModal('ViewCarrierCard', {
                CarrierID: it?.value?.Organization?.CarrierID,
                OrganizationID: it?.value?.Organization?.OrganizationID
            }))
        }
    }

    handleSelectFirstElement = (event) => {
        if (event.key === 'Tab') {
            event.preventDefault()
            event.stopPropagation();

            let edit = this.state.edit;
            if (!edit[0]) {
                edit[0] = {}
            }
            edit[0]['Description'] = true;
            this.setState({edit});
        }
    }

    handleTableFieldKeyDown = (event, index, currentField, nextField) => {
        if (event.key === 'Tab') {
            if (currentField === 'TaxID') {
                const items = dirtyClone(this.state.items)
                let last = Object.keys(items)[Object.keys(items).length - 1];
                if (index === Number(last)) {
                    if (!!this.addBtnRef?.current) {
                        this.addBtnRef?.current.focus();
                        event.preventDefault()
                        event.stopPropagation();
                        return;
                    }
                } else {
                    index = index + 1;
                }
            }

            event.preventDefault()
            event.stopPropagation();
            let edit = this.state.edit;
            if (!edit[index]) {
                edit[index] = {}
            }
            edit[index][nextField] = !edit[index][nextField];
            this.setState({edit});
        }
        if (event.key === 'Enter' && currentField != 'AccountID' && currentField != 'TaxID') {
            let edit = this.state.edit;
            edit[index][currentField] = !edit[index][currentField];
            this.setState({edit});
        }
    }

    calculateTotal = (index) => {
        let allFields = this.state.items;
        let fields = allFields[index];

        let total = (fields?.TaxID?.value?.amount ? fields.TaxID.value?.amount : 1)
            * numberWithCommasToBack(fields?.Qty?.value)
            * numberWithCommasToBack(fields?.Amount?.value);

        let totalCalculation = numberWithCommas(Math.round(total * 100) / 100) ? numberWithCommas(Math.round(total * 100) / 100) : 0

        fields.TotalAmount.value = totalCalculation;
        allFields[index] = fields;
        this.setState({items: allFields})
        return totalCalculation
    }



    handleViewReportClick = (selectedItem) => {
        this.setState({
            selectedItem: selectedItem,
            reportModalOpen: !this.state.reportModalOpen
        })
    }

    handleChargeToInputChange = (fields, name, value) => {
        fields = FieldsManager.updateField(fields, name, value)

        if (name === 'ChargeTo') {
            fields['ChargeToContactID'].value = ''
            fields['ChargeToOrganizationID'].value = ''

            fields['ChargeToContactID'].type = value === 1 ? 'select-search' : 'hidden'
            fields['ChargeToOrganizationID'].type = value === 2 ? 'select-search' : 'hidden'

            fields['ChargeToContactID'].disabled = value === 2
            fields['ChargeToOrganizationID'].disabled = value === 1
            fields.ChargeTo.value = value
        }

        if ('ChargeToContactID' === name) {
            fields.ChargeToContact.value = value.label
            fields.Contact.value = value.Contact
            fields.ChargeToOrganizationID.value = ""
        }

        if ('ChargeToOrganizationID' === name) {
            fields.ChargeToOrganization.value = value.label
            fields.Organization.value = value.Organization
            fields.ChargeToContactID.value = ""
        }

        if ('Advanced' === name) {
            fields.ChargeToContactID.value = "";
            fields.ChargeToOrganizationID.value = "";
            fields.Advanced.value = value === true ? 1 : 0
            fields.ChargeTo.type = value ? 'button-group' : 'hidden'
            fields.ChargeTo.value = 1
            fields.ChargeToContactID.type = value && fields.ChargeTo.value === 1 ? 'select-search' : 'hidden'
            fields.ChargeToOrganizationID.type = value && fields.ChargeTo.value === 2 ? 'select-search' : 'hidden'

        }

        return fields
    }
    getExpenseItemField = (item = {}) => {
        const ChargeTo = item.ChargeToOrganizationID ? 2 : 1
        const FixedAssetTypeIDValues = FIXED_ASSET_TYPES
        const isAssetTruckOrTrailer = Number(item.FixedAssetTypeID) === 1 || Number(item.FixedAssetTypeID) === 2

        const fieldTemplates = {
            ItemNumber: new Field('ItemNumber', '', [''], false, 'hidden', {
                minWidth: "75",
                render: (it) =>
                    <div className="flex text-right">
                        {it.index + 1}

                        {!!it.ProductServiceID && (
                            <Button
                                onClick={() => {
                                    this.handleViewReportClick(it)
                                }}
                                hasPerm={checkPerm(Resources.ProductServiceReport, READ_PERM)}
                                className={"border-0"}
                                disabledIconClass={"relative bottom-[2px] left-1 w-4 h-4 text-red-600 -mr-1 ml-1"}
                            >
                                <InformationCircleIcon className={"w-5 h-5 text-primary relative bottom-[2px] left-2"}/>
                            </Button>
                        )}

                    </div>
            }),
            ProductServiceID: new Field('ProductServiceID', '', [''], false, 'select-search', {
                addContainerClass: 'col-span-full',
                label: 'choose_from_product_template',
                hideTable: true,
                fieldOptions: (it) => {
                    it.ProductServiceID = it.value?.value
                    //
                    // if (it.value) {
                    //     if (item.PartName !== it.value.value) {
                    //         it.ProductServiceID = it.value.value
                    //     }
                    // }

                    return (
                        <FieldOptions
                            options={[
                                {
                                    icon: InformationCircleIcon,
                                    onClick: () => {
                                        this.handleViewReportClick(it.value?.metadata ?? it)
                                    },
                                    isVisible: !!it.value && checkPerm(Resources.ProductServiceReport, READ_PERM)
                                }
                            ]}
                        />
                    )
                }
            }, {isClearable: true}),

            Description: new Field('Description', '', ['empty'], false, 'textarea', {
                minWidth: "168",
                addContainerClass: 'col-span-full'
            }),
            AccountID: new Field('AccountID', '', ['empty'], false, 'select-search', {
                minWidth: "300",
                addContainerClass: 'col-span-full'
            }, {}),
            PrepaidExpense: new Field('PrepaidExpense', '', [], false, !item ? 'checkbox' : 'hidden', {
                hideTable: true,
                addContainerClass: 'col-span-full'
            }),
            PrepaidExpenseAccountID: new Field('PrepaidExpenseAccountID', '', [''], false, item.PrepaidExpenseAccountID ? 'select-search' : 'hidden', {
                hideTable: true,
                isClearable: true,
                addContainerClass: 'col-span-full'
            }, {
                query: {
                    AccountTypeID: MAJOR_ACCOUNT_TYPE_EXPENSE
                }
            }),
            PrepaidExpenseCount: new Field('PrepaidExpenseCount', 1, ['empty'], false, item.PrepaidExpenseAccountID ? 'integer' : 'hidden', {
                hideTable: true,
                addContainerClass: 'col-span-full'
            }, {min: 1}),

            Amount: new Field('Amount', '', [''], false, 'money', {
                minWidth: "150",
                addContainerClass: 'col-span-full',
                addTableHeaderClass: 'mx-auto'
            }, {
                isNegativeAllowed: true
            }),
            Qty: new Field('Qty', 1, ['required', 'integer_new', 'non-zero'], false, 'text', {
                minWidth: "100",
                addContainerClass: 'col-span-full',
                addTableHeaderClass: 'mx-auto',
                tableAlign: "right"
            }),
            IsPayed: new Field('IsPayed', item.IsPayed ?? 0, [''], false, 'hidden', {hideTable: true}),
            TaxID: new Field('TaxID', '', [''], false, 'select-search', {
                minWidth: "300",
                addContainerClass: 'col-span-full', addTableHeaderClass: 'mx-auto'
            }, {isClearable: true}),
            TotalAmount: new Field('TotalAmount', 0, [], true, 'money', {addContainerClass: 'col-span-full'}),

            Advanced: new Field('Advanced', '', [], false, 'switch', {
                addContainerClass: 'py-3 px-2 col-span-full flex items-center border border-tm-gray-200 rounded-card',
                addTableHeaderClass: 'text-center',
                label: "ChargeTo",
                htmlBefore: () => <div className="min-w-0 flex-1 text-sm">
                    <label htmlFor="person-3"
                           className="font-medium text-tm-gray-900 select-none">
                        {this.props.translate('field.Advanced')}
                    </label>

                    <p className="text-tm-gray-700">Show additional charge options</p>
                </div>
            }),
            ChargeTo: new Field('ChargeTo', ChargeTo, [''], false, item.Advanced ? 'button-group' : 'hidden', {
                data: {
                    1: this.props.translate('field.ChargeToContactID'),
                    2: this.props.translate('field.ChargeToOrganizationID')
                },
                hideTable: true,
                hideLabel: true,
                addContainerClass: 'col-span-full'
            }),
            ChargeToContactID: new Field('ChargeToContactID', '', [], false, item.ChargeToContactID ? 'select-search' : 'hidden', {
                addContainerClass: 'col-span-full',
                hideTable: true,
                fieldOptions: (it) => {
                    return (
                        <FieldOptions
                            options={[
                                {
                                    icon: InformationCircleIcon,
                                    onClick: () => this.props.dispatch(showModal('ViewContactCard', {ContactID: it?.value?.Contact?.ContactID})),
                                    isVisible: !!it.value
                                }
                            ]}
                        />
                    )
                }
            }, {menuPlacement: 'top'}),
            ChargeToOrganizationID: new Field('ChargeToOrganizationID', '', [], false, item.ChargeToOrganizationID ? 'select-search' : 'hidden', {
                addContainerClass: 'col-span-full',
                hideTable: true,
                fieldOptions: (it) => {
                    return (
                        <FieldOptions
                            options={[
                                {
                                    icon: InformationCircleIcon,
                                    onClick: () => this.handleOrganizationQuickView(it),
                                    isVisible: !!it.value
                                }
                            ]}
                        />
                    )
                }
            }, {menuPlacement: 'top'}),
            ExpenseLineID: new Field('ExpenseLineID', '', [''], true, 'hidden', {hideTable: true}),
            Assets: new Field('Assets', '', [], false, 'switch', {
                    addContainerClass: 'py-3 px-2 col-span-full flex items-center border border-tm-gray-200 rounded-card',
                    hideTable: true,
                    htmlBefore: () => <div className="min-w-0 flex-1 text-sm">
                        <label htmlFor="person-3"
                               className="font-medium text-tm-gray-900 select-none">
                            {this.props.translate('field.Assets')}
                        </label>

                        <p className="text-tm-gray-700">Buying asset</p>
                    </div>
                }
            ),
            AssetNumber: new Field('AssetNumber', '', [], false, item.Assets ? 'text' : 'hidden', {
                addContainerClass: 'col-span-full',
                hideTable: true,
            }),
            SerialNumber: new Field('SerialNumber', '', [], false, item.Assets ? 'text' : 'hidden', {
                addContainerClass: 'col-span-full',
                hideTable: true
            }),
            SalvageAmount: new Field('SalvageAmount', '', ['float_or_empty'], false, item.Assets ? 'text' : 'hidden', {
                hideTable: true,
                addContainerClass: 'col-span-full'
            }, {step: '0.01'}),
            StartDate: new Field('StartDate', '', [], false, item.Assets ? 'date' : 'hidden', {hideTable: true}),
            EndDate: new Field('EndDate', '', [], false, item.Assets ? 'date' : 'hidden', {hideTable: true}, {placement: 'bottom-end'}),
            AccumulatedAccountID: new Field('AccumulatedAccountID', '', item.Assets ? ['empty'] : [''], false, item.Assets ? 'select-search' : 'hidden', {
                hideTable: true,
                addContainerClass: 'col-span-full'
            }, {menuPlacement: 'top'}),
            DepExpenseAccountID: new Field('DepExpenseAccountID', '', item.Assets ? ['empty'] : [''], false, item.Assets ? 'select-search' : 'hidden', {
                addContainerClass: 'col-span-full',
                hideTable: true
            }, {menuPlacement: 'top'}),
            FixedAssetTypeID: new Field('FixedAssetTypeID', '', item.Assets ? ['empty'] : [''], false, item.Assets ? 'select' : 'hidden', {
                hideTable: true,
                addContainerClass: 'col-span-full'
            }, {
                menuPlacement: 'top',
                values: FixedAssetTypeIDValues
            }),
            ProductServiceTypeID: new Field('ProductServiceTypeID', '', [], true, 'hidden', {hideTable: true}),

            // Truck and Trailer asset type fields
            Year: new Field('Year', '', [], false, isAssetTruckOrTrailer ? 'select-search' : 'hidden', {
                htmlBefore: () =>
                    <div className="col-span-full">
                        <Subtitle
                            subtitle={this.props.translate('text.asset_details')}
                        />
                    </div>,
                hideTable: true
            }, {
                values: returnSameKeyValueObject(1900, moment().year() + 1)
            }),
            Manufacturer: new Field('Manufacturer', '', [], false, isAssetTruckOrTrailer ? 'text' : 'hidden', {hideTable: true}),
            Model: new Field('Model', '', [], false, isAssetTruckOrTrailer ? 'text' : 'hidden', {hideTable: true}),
            VIN: new Field('VIN', '', [], false, isAssetTruckOrTrailer ? 'text' : 'hidden', {hideTable: true}, {
                max: VIN_CODE_FIELD_LENGTH,
                upperCaseOnly: true,
                numbersAndLettersOnly: true
            }),
        }

        if (item) {
            if (item.ChargeToContactID) {
                item.Advanced = 1
                item.ChargeTo = 1
            }
            if (item.ChargeToOrganizationID) {
                item.Advanced = 1
                item.ChargeTo = 2
            }
            if (item.PrepaidExpenseAccountID) {
                item.PrepaidExpense = 1
            }
        }

        if (Object.keys(item).length > 0) {
            const fieldsUpdate = fillFieldsFromData(fieldTemplates, item);

            if (item?.TaxAmount) {
                fieldsUpdate.TaxID.value.amount = item.TaxAmount;
            }

            return fieldsUpdate;
        }

        return fieldTemplates;
    }

    getChargeToFields = (item = null) => {
        const ChargeTo = item?.ChargeTo ?? 0
        const fieldTemplates = {
            ChargeToContact: new Field('ChargeToContact', '', [], false, 'hidden'),
            ChargeToOrganization: new Field('ChargeToOrganization', '', [], false, 'hidden'),
            Contact: new Field('Contact', '', [], false, 'hidden'),
            Organization: new Field('Organization', '', [], false, 'hidden'),
            Advanced: new Field('Advanced', '', [], false, 'switch', {
                addContainerClass: 'py-3 px-2 col-span-full flex items-center border border-tm-gray-200 rounded-card',
                label: "ChargeTo",
                htmlBefore: () => <div className="min-w-0 flex-1 text-sm">
                    <label htmlFor="person-3"
                           className="font-medium text-tm-gray-900 select-none">
                        {this.props.translate('field.Advanced')}
                    </label>

                    <p className="text-tm-gray-700">Show additional charge options</p>
                </div>
            }),
            ChargeTo: new Field('ChargeTo', ChargeTo, [''], false, item?.Advanced ? 'button-group' : 'hidden', {
                data: {
                    1: this.props.translate('field.ChargeToContactID'),
                    2: this.props.translate('field.ChargeToOrganizationID')
                },
                hideTable: true,
                hideLabel: true,
                addContainerClass: 'col-span-full',
            }),
            ChargeToContactID: new Field('ChargeToContactID', '', [], false, (item?.Advanced && item?.ChargeToContactID) ? 'select-search' : 'hidden', {
                addContainerClass: 'col-span-full',
                hideTable: true,
                fieldOptions: (it) => {
                    return (
                        <FieldOptions
                            options={[
                                {
                                    icon: InformationCircleIcon,
                                    onClick: () => this.props.dispatch(showModal('ViewContactCard', {ContactID: it?.value?.Contact?.ContactID})),
                                    isVisible: !!it.value
                                }
                            ]}
                        />
                    )
                }
            }, {menuPlacement: 'top'}),
            ChargeToOrganizationID: new Field('ChargeToOrganizationID', '', [], false, (item?.Advanced && item?.ChargeToOrganizationID) ? 'select-search' : 'hidden', {
                addContainerClass: 'col-span-full',
                hideTable: true,
                fieldOptions: (it) => {
                    return (
                        <FieldOptions
                            options={[
                                {
                                    icon: InformationCircleIcon,
                                    onClick: () => this.handleOrganizationQuickView(it),
                                    isVisible: !!it.value
                                }
                            ]}
                        />
                    )
                }
            }, {menuPlacement: 'top'}),
        }

        return fillFieldsFromData(fieldTemplates, item)
    }

    getIncomeItemFields = (item = {}) => {
        const ChargeTo = item.ChargeTo ?? 1

        const fieldTemplates = {
            ItemNumber: new Field('ItemNumber', '', [''], false, 'hidden', {
                minWidth: "75",
                render: (it) =>
                    <div className="flex text-right">
                        {it.index + 1}

                        {!!it.ProductServiceID && (
                            <Button
                                onClick={() => {
                                    this.handleViewReportClick(it)
                                }}
                                hasPerm={checkPerm(Resources.ProductServiceReport, READ_PERM)}
                                className={"border-0"}
                                disabledIconClass={"relative bottom-[2px] left-1 w-4 h-4 text-red-600 -mr-1 ml-1"}
                            >
                                <InformationCircleIcon className={"w-5 h-5 text-primary relative bottom-[2px] left-2"}/>
                            </Button>
                        )}

                    </div>
            }),
            ProductServiceID: new Field('ProductServiceID', '', [''], false, 'select-search', {
                addContainerClass: 'col-span-full',
                label: 'choose_from_product_template',
                hideTable: true,
                fieldOptions: (it) => {
                    it.ProductServiceID = it.value?.value

                    return (
                        <FieldOptions
                            options={[
                                {
                                    icon: InformationCircleIcon,
                                    onClick: () => {
                                        this.handleViewReportClick(it.value?.metadata ?? it)
                                    },
                                    isVisible: !!it.value && checkPerm(Resources.ProductServiceReport, READ_PERM)
                                }
                            ]}
                        />
                    )
                }
            }, {isClearable: true}),

            Description: new Field('Description', '', ['empty'], false, 'textarea', {
                render: (it) => {
                    const index = it.index;
                    let items = this.state.items;
                    let errorMessage = items[index]?.Description?.errorMessage

                    return (
                        <div
                            className={classNames(errorMessage && !this.state.edit[index]?.Description ? 'border border-red-600 rounded-md leading-8 mt-2' : null, "pl-2 flex justify-start items-center")}>{!this.state.edit[index]?.Description ?
                                <div className={"cursor-pointer"} onClick={() => {
                                    if (it.IsPayed !== 1) {
                                        let edit = this.state.edit;
                                        edit[index] = {};
                                        edit[index].Description = true;
                                        this.setState({edit})
                                    }

                                }
                                }>{it.Description}
                                    {it.IsPayed !== 1 && (<button title={this.props.translate("btn.edit")}><PencilIcon
                                        className={"ml-2 mb-1 inline w-3 h-3"}/></button>)}
                                </div> : <div className={"w-40"}>
                                    <FieldText
                                        addClass={"form-control -ml-2 relative text-xs"}
                                        name={'Description'}
                                        value={items?.[index]?.Description?.value ?? ""}
                                        autoFocus
                                        onBlur={() => {
                                            let edit = this.state.edit;
                                            edit[index].Description = !edit[index].Description;
                                            this.setState({edit})
                                        }}

                                        onFocus={(event) => event.target.select()}
                                        onKeyDown={(event) => this.handleTableFieldKeyDown(event, index, 'Description', 'AccountID')}

                                        onChange={(name, value) => {
                                            let fieldItems = [...this.state.items]
                                            fieldItems[index][name].value = value
                                            fieldItems[index].Description.errorMessage = null
                                            this.setState({
                                                isDirty: true,
                                                items: fieldItems
                                            });
                                        }}

                                    />
                                </div>}
                            </div>
                        )
                    },
                    addContainerClass: 'col-span-full'
                }
            ),
            AccountID: new Field('AccountID', '', ['empty'], false, 'select-search', {
                minWidth: "300",
                render: (it) => {
                    const index = it.index;
                    let fieldItems = [...this.state.items]
                    let value = fieldItems[index]?.AccountID.value
                    let errorMessage = fieldItems[index]?.AccountID?.errorMessage
                    return (
                        <div
                            className={classNames(errorMessage && !this.state.edit[index]?.AccountID ? 'border border-red-600 rounded-md leading-8 mt-2' : null, "pl-2 flex justify-start items-center")}>{!this.state.edit[index]?.AccountID ?
                            <div className={"cursor-pointer"} onClick={() => {
                                if (it.IsPayed !== 1) {
                                    let edit = this.state.edit;
                                    edit[index] = {};
                                    edit[index].AccountID = true;
                                    this.setState({edit})
                                }
                            }
                            }>{it.Account}
                                {it.IsPayed !== 1 && (<button title={this.props.translate("btn.edit")}><PencilIcon
                                    className={"ml-2 mb-1 inline w-3 h-3"}/></button>)}
                            </div> : <div className="" onBlur={() => {
                                let edit = this.state.edit;
                                edit[index].AccountID = !edit[index].AccountID;
                                this.setState({edit})
                            }}>

                                <FieldDropdownSelect
                                    onChange={(name, value) => {
                                        let fieldItems = [...this.state.items]
                                        fieldItems[index][name].value = value
                                        fieldItems[index].AccountID.errorMessage = null
                                        this.setState({isDirty: true, items: fieldItems});
                                    }}
                                    value={value}
                                    autoFocus
                                    name={'AccountID'}
                                    addClass="form-control w-[250px] -ml-2 relative "
                                    defaultOptions={true}
                                    placeholder={"Select account"}
                                    hasPortal
                                    onKeyDown={(event) => this.handleTableFieldKeyDown(event, index, 'AccountID', 'Amount')}
                                    loadOptions={
                                        (inputValue, callback) => {
                                            axios.get(
                                                Env.getApiUrl("api/" + Resources.AccountsQuick, {query: inputValue}),
                                                {
                                                    headers: {
                                                        'Authorization': 'Bearer ' + getJWT().access_token
                                                    }
                                                }
                                            )
                                                .then((response) => {
                                                    const result = processResponse(response);
                                                    if (result && result.status === 0) {
                                                        const list = result.data.list.map((it) => {
                                                            return {
                                                                label: it.AccountNumber + ' ' + it.AccountName,
                                                                value: it.AccountID
                                                            };
                                                        });
                                                        callback(list);
                                                    }
                                                })
                                        }
                                    }
                                />
                            </div>}
                        </div>
                    )
                },
                addContainerClass: 'col-span-full'
            }, {}),
            Amount: new Field('Amount', '', [''], false, 'money', {
                minWidth: "150",
                addContainerClass: 'col-span-full',
                addTableHeaderClass: 'mx-auto'
            }, {
                isNegativeAllowed: true
            }),
            Qty: new Field('Qty', 1, ['required', 'integer_new', 'min:1'], false, 'text', {
                minWidth: "100",
                addContainerClass: 'col-span-full',
                addTableHeaderClass: 'mx-auto',
                tableAlign: "right"
            }),
            TaxID: new Field('TaxID', '', [''], false, 'select-search', {
                minWidth: "300",
                render: (it) => {

                    const index = it.index;
                    let fieldItems = [...this.state.items]
                    let value = fieldItems[index]?.TaxID.value
                    let errorMessage = fieldItems[index]?.TaxID?.errorMessage

                    return (
                        <div
                            className={classNames(errorMessage && !this.state.edit[index]?.TaxID ? 'border border-red-600 rounded-md leading-8 mt-2' : null, "pl-2 flex flex-display-start")}>{!this.state.edit[index]?.TaxID ?
                            <div className={"w-40 cursor-pointer"} onClick={() => {
                                if (it.IsPayed !== 1) {
                                    let edit = this.state.edit;
                                    edit[index] = {};
                                    edit[index].TaxID = true;
                                    this.setState({edit})
                                }

                            }
                            }>
                                {it.Tax ? it.Tax : "No tax selected"}
                                {it.IsPayed !== 1 && (<button title={this.props.translate("btn.edit")}>
                                    <PencilIcon
                                        className={"ml-2 mb-1 inline w-3 h-3"}/></button>)}
                            </div> : <div className="" onBlur={() => {
                                let edit = this.state.edit;
                                edit[index].TaxID = !edit[index].TaxID;
                                this.setState({edit})
                            }}>
                                <FieldDropdownSelect
                                    onChange={(name, value) => {
                                        let fieldItems = [...this.state.items]
                                        fieldItems[index][name].value = value
                                        fieldItems[index].TaxID.errorMessage = null

                                        this.setState({isDirty: true, items: fieldItems});

                                        this.calculateTotal(index)
                                    }}
                                    autoFocus
                                    value={value}
                                    isClearable
                                    name={'TaxID'}
                                    addClass="form-control w-40 text-center -ml-2 relative"
                                    defaultOptions={true}
                                    onKeyDown={(event) => this.handleTableFieldKeyDown(event, index, 'TaxID', 'Description')}
                                    hasPortal
                                    placeholder={"Select tax"}
                                    loadOptions={
                                        (inputValue, callback) => {
                                            axios.get(
                                                Env.getApiUrl("api/" + Resources.AccountingTaxes, {query: inputValue}),
                                                {
                                                    headers: {
                                                        'Authorization': 'Bearer ' + getJWT().access_token
                                                    }
                                                }
                                            )
                                                .then((response) => {
                                                    const result = processResponse(response);
                                                    if (result && result.status === 0) {
                                                        const list = result.data.list.map((it) => {
                                                            return {
                                                                label: it.Tax,
                                                                value: it.TaxID,
                                                                amount: it.Amount
                                                            };
                                                        });
                                                        callback(list);
                                                    }
                                                })
                                        }
                                    }
                                />
                            </div>}
                        </div>
                    )
                },
                addContainerClass: 'col-span-full', addTableHeaderClass: 'mx-auto'
            }, {isClearable: true}),

            TotalAmount: new Field('TotalAmount', 0, [], true, 'money', {addContainerClass: 'col-span-full'}),
            Advanced: new Field('Advanced', '', [], false, 'switch', {
                addContainerClass: 'py-3 px-2 col-span-full flex items-center border border-tm-gray-200 rounded-card',
                addTableHeaderClass: 'text-center',
                label: "ChargeTo",
                htmlBefore: () => <div className="min-w-0 flex-1 text-sm">
                    <label htmlFor="person-3"
                           className="font-medium text-tm-gray-900 select-none">
                        {this.props.translate('field.Advanced')}
                    </label>

                    <p className="text-tm-gray-700">Show additional charge options</p>
                </div>
            }),
            ChargeTo: new Field('ChargeTo', ChargeTo, [''], false, item.Advanced ? 'button-group' : 'hidden', {
                data: {
                    1: this.props.translate('field.ChargeToContactID'),
                    2: this.props.translate('field.ChargeToOrganizationID')
                },
                hideTable: true,
                hideLabel: true,
                addContainerClass: 'col-span-full',
            }),
            ChargeToContactID: new Field('ChargeToContactID', '', [], false, item.ChargeToContactID ? 'select-search' : 'hidden', {
                addContainerClass: 'col-span-full',
                hideTable: true,
                fieldOptions: (it) => {
                    return (
                        <FieldOptions
                            options={[
                                {
                                    icon: InformationCircleIcon,
                                    onClick: () => this.props.dispatch(showModal('ViewContactCard', {ContactID: it?.value?.Contact?.ContactID})),
                                    isVisible: !!it.value
                                }
                            ]}
                        />
                    )
                }
            }, {menuPlacement: 'top'}),
            ChargeToOrganizationID: new Field('ChargeToOrganizationID', '', [], false, item.ChargeToOrganizationID ? 'select-search' : 'hidden', {
                addContainerClass: 'col-span-full',
                hideTable: true,
                fieldOptions: (it) => {
                    return (
                        <FieldOptions
                            options={[
                                {
                                    icon: InformationCircleIcon,
                                    onClick: () => this.handleOrganizationQuickView(it),
                                    isVisible: !!it.value
                                }
                            ]}
                        />
                    )
                }
            }, {menuPlacement: 'top'}),

            ProductServiceTypeID: new Field('ProductServiceTypeID', '', [], true, 'hidden', {hideTable: true}),
            InvoiceLineID: new Field('InvoiceLineID', '', [''], true, 'hidden', {hideTable: true}),
            Assets: new Field('Assets', '', [], false, 'switch', {
                    addContainerClass: 'py-3 px-2 col-span-full flex items-center border border-tm-gray-200 rounded-card',
                    hideTable: true,
                    htmlBefore: () => <div className="min-w-0 flex-1 text-sm">
                        <label htmlFor="person-3"
                               className="font-medium text-tm-gray-900 select-none">
                            {this.props.translate('field.Assets')}
                        </label>

                        <p className="text-tm-gray-700">Selling asset</p>
                    </div>
                }
            ),
            AssetID: new Field('AssetID', '', item.Assets ? ['empty'] : [''], false, item.Assets ? 'select-search' : 'hidden', {
                addContainerClass: 'col-span-full',
                hideTable: true
            }, {menuPlacement: 'top'}),
        }

        if (item) {
            if (item.ChargeToContactID) {
                item.ContactID = item.ChargeToContactID;
                fieldTemplates['Advanced'].value = 1
                fieldTemplates['ChargeTo'].value = 1
            }
            if (item.ChargeToOrganizationID) {
                item.OrganizationID = item.ChargeToOrganizationID;
                fieldTemplates['Advanced'].value = 1
                fieldTemplates['ChargeTo'].value = 2
            }
        }

        if (Object.keys(item).length > 0) {
            const fieldsUpdate = fillFieldsFromData(fieldTemplates, item);

            if (item?.TaxAmount) {
                fieldsUpdate.TaxID.value.amount = item.TaxAmount;
            }

            return fieldsUpdate;
        }

        return fieldTemplates;
    }

    getValidFields = () => {
        const isUpdate =
            getProp(this.props.updateItem, 'InvoiceID', '')
            ||
            getProp(this.props.updateItem, 'ExpenseID', '')

        let list = ['OrganizationID', 'ContactID', 'PaymentTypeID', 'Date', 'AccountID', 'BookDate', 'OfficeID', 'ContactGroupID', 'CheckNumber']

        if ((this.state.dialogType === 'invoice') || (this.state.dialogType === 'bill')) {
            list = ['OrganizationID', 'ContactID', 'Date', 'BookDate', 'DueDate', 'OfficeID', 'ContactGroupID']
        }

        if (!this.state.AutoGenerateRef && (this.isIncome() || this.isIExpense())) {
            list.push('RefNumber')
        }

        let fields = FieldsManager.validateFields(this.state.fields, list)

        let items = this.state.items.map((fields) => FieldsManager.validateFields(fields))

        if (FieldsManager.checkFieldsForErrors(fields, list) && this.checkItemsForError()) {
            return Object.assign({}, FieldsManager.getFieldKeyValues(this.state.fields), {
                Items: this.state.items.map((fields) => Object.assign({}, FieldsManager.getFieldKeyValues(fields), {
                    Amount: numberWithCommasToBack(fields.Amount.value),
                    TotalAmount: numberWithCommasToBack(fields.TotalAmount.value)
                })),
                Amount: numberWithCommasToBack(this.TotalAmount),
                id: isUpdate
            })
        } else {
            this.setState({
                ErrorMessage: (<div
                    className={"text-red-500 mr-20 font-16px"}>{this.props.translate('text.pleaseCheckItems')}</div>),
                fields: fields,
                items: items
            })
            return false
        }
    }

    getValidNotePayableFields = () => {
        let fields = cloneDeep(this.state.notePayableFields)

        if (!fields.IsDelayedPayment.value) {
            return true
        }

        fields = FieldsManager.validateFields(fields)

        if (FieldsManager.checkFieldsForErrors(fields)) {
            return true
        } else {
            this.setState({
                notePayableFields: fields
            })
        }
    }

    getDialogResource = () => {
        switch (this.state.dialogType) {
            case 'receipt':
                return Resources.InvoicesReceipt
            case 'invoice':
                return Resources.InvoicesInvoice
            case 'bill':
                return Resources.ExpenseBill
            case 'expense':
                return Resources.ExpenseExpense
            default:
                console.log('noType')
        }
    }

    getDialogDocumentResource = () => {
        switch (this.state.dialogType) {
            case 'receipt':
            case 'invoice':
                return Resources.InvoicesDocuments
            case 'bill':
            case 'expense':
                return Resources.ExpenseDocuments
            default:
                console.log('noType')
        }
    }

    getDialogType = () => {
        const invoiceType = getProp(this.props.updateItem, 'InvoiceTypeID', null)
        const expenseType = getProp(this.props.updateItem, 'ExpenseTypeID', null)
        let dialogType

        if (invoiceType) {
            dialogType = invoiceType == 1 ? 'invoice' : 'receipt'
        }

        if (expenseType) {
            dialogType = expenseType == 1 ? 'bill' : 'expense'
        }

        return dialogType
    }

    getResource = () => {
        const invoiceType = getProp(this.props.updateItem, 'InvoiceTypeID', null)
        const expenseType = getProp(this.props.updateItem, 'ExpenseTypeID', null)

        if (invoiceType) {
            return Resources.InvoicesInvoice
        }

        if (expenseType) {
            return Resources.ExpenseExpense
        }
    }

    checkItemsForError = () => {
        let itemFieldCheck = this.state.items.map((fields) => {
            const validate = FieldsManager.validateFields(fields);
            return FieldsManager.checkFieldsForErrors(validate)
        })

        return !itemFieldCheck.includes(false)
    }

    toggleInternalNotesVisibility = () => {
        const fields = this.state.fields;
        if (fields.InternalNotes.type === 'hidden') {
            fields.InternalNotes.type = 'textarea';
        } else {
            fields.InternalNotes.type = 'hidden';
        }

        fields.InternalNotes.value = "";

        this.setState({
            fields: fields
        });
    }

    toggleExternalNotesVisibility = () => {
        const fields = this.state.fields;
        if (fields.ExternalNotes.type === 'hidden') {
            fields.ExternalNotes.type = 'textarea';
        } else {
            fields.ExternalNotes.type = 'hidden';
        }

        fields.ExternalNotes.value = "";

        this.setState({
            fields: fields
        });
    }

    handleInputChange = (name, value) => {
        let fields = this.state.fields
        if (!this.props.updateItem
            && ((this.state.dialogType === 'invoice'))
            && name === 'OrganizationID' && (!!this.getDefaultNetTerm() || value?.metadata?.NetTermDays)) {
            fields = FieldsManager.updateField(fields, 'DueDate', moment().add(value?.metadata?.NetTermDays ?? this.getDefaultNetTerm(), 'days').format('YYYY-MM-DD HH:mm:ss'))
        }
        if (!this.props.updateItem
            && ((this.state.dialogType === 'bill'))
            && name === 'OrganizationID' && (!!this.getDefaultNetTerm() || !!value?.metadata?.PaymentNetTermTypeDays)) {
            fields = FieldsManager.updateField(fields, 'DueDate', moment().add(value?.metadata?.PaymentNetTermTypeDays ?? this.getDefaultNetTerm(), 'days').format('YYYY-MM-DD HH:mm:ss'))
        }

        if (!this.props.updateItem
            && ((this.state.dialogType === 'receipt')) && name === 'OrganizationID'
            && (value?.metadata?.IncomeAccountID)) {
            fields = FieldsManager.updateField(fields, 'AccountID', value?.metadata?.IncomeAccountID)
        }
        if (!this.props.updateItem
            && ((this.state.dialogType === 'expense'))
            && name === 'OrganizationID'
            && (value?.metadata?.PaymentAccountID)) {
            fields = FieldsManager.updateField(fields, 'AccountID', value?.metadata?.ExpenseAccountID)
        }

        if (!this.props.updateItem
            && ((this.state.dialogType === 'invoice'))
            && name === 'Date' && (this.getDefaultNetTerm() || fields.OrganizationID?.value?.metadata?.NetTermDays)) {
            fields = FieldsManager.updateField(fields, 'DueDate', moment(value).add(fields.OrganizationID?.value?.metadata?.NetTermDays ?? this.getDefaultNetTerm(), 'days').format('YYYY-MM-DD HH:mm:ss'))
        }
        if (!this.props.updateItem
            && ((this.state.dialogType === 'bill'))
            && name === 'Date'
            && (this.getDefaultNetTerm() || !!fields.OrganizationID?.value?.metadata?.PaymentNetTermTypeDays)) {

            fields = FieldsManager.updateField(fields, 'DueDate', moment(value).add(fields.OrganizationID?.value?.metadata?.PaymentNetTermTypeDays ?? this.getDefaultNetTerm(), 'days').format('YYYY-MM-DD HH:mm:ss'))
        }

        if (name === 'ChargeTo') {
            fields.ContactID.validate = [value == "1" ? 'empty' : '']
            fields.OrganizationID.validate = [value == "2" ? 'empty' : '']

            fields.ContactID.value = ''
            fields.OrganizationID.value = ''
        }
        if (name === 'PaymentTypeID') {
            let QueryAccountTypeID = ACCOUNT_TYPE_CASH
            if ((this.state.dialogType === 'receipt')) {
                switch (+value) {
                    case PAYMENT_METHOD_CACHE:
                        QueryAccountTypeID = ACCOUNT_TYPE_CASH
                        fields.CheckNumber.type = 'hidden';
                        fields.CheckNumber.validate = [''];
                        break
                    case PAYMENT_METHOD_CHECK:
                        QueryAccountTypeID = ACCOUNT_TYPE_OTHER_CURRENT_ASSETS
                        fields.CheckNumber.type = 'text';
                        fields.CheckNumber.validate = ['empty'];
                        break
                    case PAYMENT_METHOD_CARD:
                        QueryAccountTypeID = ACCOUNT_TYPE_CASH
                        fields.CheckNumber.type = 'hidden';
                        fields.CheckNumber.validate = [''];
                        break
                    case PAYMENT_METHOD_DEBIT:
                        QueryAccountTypeID = ACCOUNT_TYPE_CASH
                        fields.CheckNumber.type = 'hidden';
                        fields.CheckNumber.validate = [''];
                        break
                    case PAYMENT_METHOD_FACTOR:
                        fields.CheckNumber.type = 'hidden';
                        fields.CheckNumber.validate = [''];
                        break
                    case PAYMENT_METHOD_PAYMENT_PROCESSOR:
                        fields.CheckNumber.type = 'hidden';
                        fields.CheckNumber.validate = [''];
                        break
                }
            } else if (this.state.dialogType === 'expense') {
                switch (+value) {
                    case PAYMENT_METHOD_CACHE:
                        QueryAccountTypeID = ACCOUNT_TYPE_CASH
                        break
                    case PAYMENT_METHOD_CHECK:
                        QueryAccountTypeID = ACCOUNT_TYPE_CASH
                        break
                    case PAYMENT_METHOD_CARD:
                        QueryAccountTypeID = ACCOUNT_CREDIT_CARD
                        break
                    case PAYMENT_METHOD_DEBIT:
                        QueryAccountTypeID = ACCOUNT_TYPE_CASH
                        break
                    case PAYMENT_METHOD_PAYMENT_PROCESSOR:
                        QueryAccountTypeID = ACCOUNT_TYPE_CASH
                        break
                }
            }
            fields.AccountID.value = ''

            this.setState({
                QueryAccountTypeID: QueryAccountTypeID,
                isDirty: true
            })
        }

        fields = FieldsManager.updateField(fields, name, value)
        this.setState({
            fields: fields,
            isDirty: true
        })
    }

    handleItemInputChange = (name, value, index) => {
        let items = dirtyClone(this.state.items)
        let item = items[index]

        const isIncome = this.isIncome()

        items[index] = FieldsManager.updateField(item, name, value)

        let updateTotal = false

        if (name === 'ProductServiceID') {
            items[index] = FieldsManager.updateField(item, 'Description',
                isIncome ? value.metadata?.IncomeDescription : value.metadata?.ExpenseDescription
            )
            items[index] = FieldsManager.updateField(item, 'Amount',
                isIncome ? (value.metadata?.IncomePrice ?? 0) : (value.metadata?.ExpensePrice ?? 0)
            )
            updateTotal = true
        }

        if (name === 'Qty' || name === 'Amount' || name === 'TaxID' || updateTotal) {
            let total = numberWithCommasToBack(
                (item.TaxID?.value?.amount ?? 1) *
                (item.Qty.value) *
                numberWithCommasToBack(item.Amount.value)
            )

            items[index] = FieldsManager.updateField(item, 'TotalAmount', numberWithCommas(Math.round(total * 100) / 100))
        }

        if (name === 'Total') {
            items[index] = FieldsManager.updateField(item, 'Amount', value)
        }

        items[index][name].errorMessage = [];

        this.setState({
            items: items,
            isDirty: true
        })
    }

    handleShowMonthlyBreakdownClick = () => {
        this.setState({
            isMonthlyBreakdownOpen: !this.state.isMonthlyBreakdownOpen
        })
    }

    hideModal = () => {
        this.props.onClose()
    }

    handleItemDragStart = (e, i) => {
        this.setState({
            draggedItemIndex: i
        })
    }

    handleItemDragEnd = () => {
        this.setState({
            draggedItemIndex: null
        })
    }

    handleEnterItem = (i) => {
        let items = this.state.items
        let draggedItemIndex = this.state.draggedItemIndex

        if (draggedItemIndex !== i) {
            [items[draggedItemIndex], items[i]] = [items[i], items[draggedItemIndex]]
        }

        this.setState({
            draggedItemIndex: i,
            items: items
        })
    }

    handleToggleViewModal = (attached) => {
        this.setState({filePreview: !this.state.filePreview, attachedDocument: attached})
    }

    addNewExpenseItem = (item = null) => {
        const items = dirtyClone(this.state.items)
        let newItem = item ?? this.getExpenseItemField()

        if (!newItem.Advanced.value) {
            newItem.ChargeTo.value = ''
            newItem.ChargeToContactID.value = ''
            newItem.ChargeToOrganizationID.value = ''
        }

        if (!newItem.Assets.value) {
            newItem.SerialNumber.value = ''
            newItem.SalvageAmount.tvalue = ''
            newItem.StartDate.value = ''
            newItem.EndDate.value = ''
            newItem.AccumulatedAccountID.value = ''
            newItem.DepExpenseAccountID.value = ''
            newItem.ProductServiceTypeID.value = ''
        }

        items.push(newItem)
        let last = Object.keys(items)[Object.keys(items).length - 1];
        let edit = this.state.edit;
        edit[last] = {};
        edit[last].Description = true

        this.setState({
            items,
            isDirty: true
        })
    }

    handleEditExpenseItem = (item, index) => {
        const items = dirtyClone(this.state.items)

        if (!item.Advanced.value) {
            item.ChargeTo.value = ''
            item.ChargeToContactID.value = ''
            item.ChargeToOrganizationID.value = ''
        }

        if (!item.Assets.value) {
            item.SerialNumber.value = ''
            item.SalvageAmount.tvalue = ''
            item.StartDate.value = ''
            item.EndDate.value = ''
            item.AccumulatedAccountID.value = ''
            item.DepExpenseAccountID.value = ''
            item.ProductServiceTypeID.value = ''
        }

        items[index] = item
        this.setState({
            items,
            isDirty: true
        })
    }

    addNewIncomeItem = (item = null) => {
        const items = dirtyClone(this.state.items)
        let newItem = item ?? this.getIncomeItemFields()
        if (item) {
            if (!newItem.Advanced.value) {
                newItem.ChargeTo.value = ''
                newItem.ChargeToContactID.value = ''
                newItem.ChargeToOrganizationID.value = ''
            }

            if (!newItem.Assets.value) {
                newItem.AssetID.value = ''
            }
        }

        items.push(newItem)
        let last = Object.keys(items)[Object.keys(items).length - 1];
        let edit = this.state.edit;
        edit[last] = {};
        edit[last].Description = true

        this.setState({
            items,
            edit,
            isDirty: true
        })
    }

    handleEditIncomeItem = (item, index) => {
        const items = dirtyClone(this.state.items)

        items[index] = item

        this.setState({
            items,
            isDirty: true
        })
    }

    handleNotePayableChange = (name, value) => {
        const fields = FieldsManager.updateField(this.state.notePayableFields, name, value)

        if ('IsDelayedPayment' === name) {
            fields.StartDate.type = value ? 'date' : 'hidden'

            fields.LoanOrganizationID.type = value ? 'select-search' : 'hidden'

            fields.DownPayment.type = value ? 'money' : 'hidden'
            fields.DownPayment.validate = value ? ['empty'] : ['']

            fields.InterestRate.type = value ? 'float' : 'hidden'
            fields.InterestRate.validate = value ? ['empty'] : ['']

            fields.LoanTerm.type = value ? 'text' : 'hidden'
            fields.LoanTerm.validate = value ? ['empty'] : ['']

            fields.LiabilityAccountID.type = value ? 'select-search' : 'hidden'
            fields.LiabilityAccountID.validate = value ? ['empty'] : ['']

            fields.InterestExpenseAccountID.type = value ? 'select-search' : 'hidden'
            fields.InterestExpenseAccountID.validate = value ? ['empty'] : ['']
        }

        fields[name].errorMessage = ''

        this.setState({
            notePayableFields: fields
        })
    }

    removeItem = index => {
        let itemsUpdate = dirtyClone(this.state.items)
        if (index > -1) {
            itemsUpdate.splice(index, 1)
        }
        this.setState({
            isDirty: true,
            items: itemsUpdate
        })
    }

    onDrop = (acceptedFiles) => {
        this.setState({
            files: acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            }))
        })
    }

    onDragLeave = () => {
        this.setState({
            dropzoneActive: false
        })
    }

    onDropAccepted = () => {
        this.setState({
            dropzoneActive: false,
            filesUploadDialog: true,
            isDirty: true
        })
    }

    createProductServiceDialog = (name, value, i) => {
        this.setState({
            ProductServiceInfoName: value,
            isProductServiceInfoDialogVisible: true,
            isInvoiceDialogHidden: true,
            ProductServiceInfoIndex: i
        })
    }

    closeProductServiceInfoDialog = () => {
        this.setState({
            isProductServiceInfoDialogVisible: false,
            isInvoiceDialogHidden: false
        })
    }

    setCreatedItemName = (name) => {
        this.setState({
            createdProductServiceName: name
        })
    }

    removeDocument = () => {
        this.setState({
            files: [],
            isDirty: true
        })
    }

    // removeAttachment = () => {
    //     this.setState({ImagePath: 1});
    // }

    escFunction = (event) => {
        if (event.keyCode === 27) {
            this.hideModal()
        }
    }

    isIncome = () => {
        return ((this.state.dialogType === 'invoice') || (this.state.dialogType === 'receipt'))
    }

    isIExpense = () => {
        return ((this.state.dialogType === 'expense') || (this.state.dialogType === 'bill'))
    }

    getDefaultNetTerm = () => {
        return this.props?.defaults?.CompanyNetTermDays ?? 30
    }

    handleGroupInfoClick = (groupData) => {
        this.setState({
            isGroupInfoDialogOpen: true,
            groupInfoData: groupData
        })
    }

    handleToggleChargeToModal = (item = null) => {
        this.setState({
            ChargeTo: item,
            isChargeToModalOpen: !this.state.isChargeToModalOpen
        })
    }

    getOrganizationMetadata = () => {
        let organizationSubtypeKey;
        const selectedItemData = getProp(this.props.secondResource, 'data', {})

        if (selectedItemData["Customer"]) {
            organizationSubtypeKey = "Customer"
        } else if (selectedItemData["Carrier"]) {
            organizationSubtypeKey = "Carrier"
        } else {
            organizationSubtypeKey = "Vendor"
        }

        return getProp(this.props.secondResource, `data.${organizationSubtypeKey}`, {})
    }

    renderAddressPreviewInfo = () => {
        const selectedOrganization = this.state.fields.OrganizationID.value.metadata || this.getOrganizationMetadata()
        const {AddressName, AddressName2, CityName, State, PostalCode} = selectedOrganization

        return (
            <InfoBar>
                <div>
                    <h3>{AddressName}</h3>
                    <div>{AddressName2}</div>
                    <p>{CityName}, {State}, {PostalCode}</p>
                </div>
            </InfoBar>
        )
    }

    render() {
        const {translate} = this.props

        let TotalAmount = dirtyClone(this.state.items).reduce((memo, it) => {
            memo = memo + numberWithCommasToBack(it.TotalAmount.value)
            return memo
        }, 0)
        this.TotalAmount = numberWithCommas(Math.round(numberWithCommasToBack(TotalAmount) * 100) / 100)

        const files = this.state.files.map((item, i) => {
            return (
                <span key={i}>{item.name}</span>
            )
        })
        const haveDocument = getProp(this.props, 'secondResource.data.AttachPath', null);

        const isReconciled = !!getProp(this.props, 'secondResource.data.ReconciliationDate', false)
        const reconciliationDate = getProp(this.props, 'secondResource.data.ReconciliationDate', null)

        const isReceiptSubtype = this.props.updateItem?.InvoiceTypeID === 2 || this.props.updateItem?.SubTypeID === 2
        const isExpenseSubtype = this.props.updateItem?.ExpenseTypeID === 2 || this.props.updateItem?.SubTypeID === 2

        const isIncomeType = this.isIncome()
        const isOrganizationSelected = !!this.state.fields.OrganizationID.value;

        const selects = {
            AccountID: {
                api: 'api/' + Resources.AccountsQuick,
                query: {},
                searchMap: (item) => ({
                    value: item.AccountID,
                    label: item.AccountName
                })
            },
            TaxID: {
                api: 'api/' + Resources.AccountingTaxes,
                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                searchMap: (item) => ({
                    label: item.Tax,
                    value: item.TaxID,
                    amount: item.Amount
                })
            },
            ChargeToOrganizationID: {
                api: 'api/' + Resources.OrganizationsQuick,
                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                searchMap: (item) => ({
                    value: item.OrganizationID,
                    label: item.LegalName,
                    Organization: item
                })
            },
            ChargeToContactID: {
                api: 'api/' + Resources.ContactsQuick,
                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                searchMap: (item) => ({
                    value: item.ContactID,
                    label: item.FirstName + ' ' + item.LastName,
                    Contact: item
                })
            }
        }

        const renderCells = {
            Description: (item) => {
                return <FieldCellTextNew
                    name="Description"
                    value={item?.['Description']}
                    onChange={(name, value) => this.handleItemInputChange(name, value, item.index)}
                    autoFocus={!item.Description}
                    disabled={item?.IsPayed}
                    errorMessage={this.state.items?.[item.index]?.['Description']?.errorMessage}
                />
            },
            AccountID: (item) => <FieldCellSelectSearch
                name="AccountID"
                value={{value: item?.['AccountID'], label: item?.['Account']}}
                onChange={(name, value) => this.handleItemInputChange(name, value, item.index)}
                placeholder={translate("text.no_account_selected")}
                isClearable={true}
                select={selects["AccountID"]}
                disabled={item?.IsPayed}
                errorMessage={this.state.items?.[item.index]?.['AccountID']?.errorMessage}
            />,
            Amount: (item) => <FieldCellMoney
                name="Amount"
                value={item?.['Amount']}
                onChange={(name, value) => this.handleItemInputChange(name, value, item.index)}
                disabled={item?.IsPayed}
                errorMessage={this.state.items?.[item.index]?.['Amount']?.errorMessage}
            />,
            Qty: (item) => <FieldCellTextNew
                align="right"
                type="text"
                name="Qty"
                value={item?.['Qty']}
                onChange={(name, value) => this.handleItemInputChange(name, value, item.index)}
                disabled={item?.IsPayed}
                errorMessage={this.state.items?.[item.index]?.['Qty']?.errorMessage}
            />,
            TaxID: (item) => <FieldCellSelectSearch
                name="TaxID"
                value={{value: item?.['TaxID'], label: item?.['Tax']}}
                onChange={(name, value) => this.handleItemInputChange(name, value, item.index)}
                placeholder={translate("text.no_tax_selected")}
                isClearable={true}
                disabled={item?.IsPayed}
                select={selects["TaxID"]}
            />,
            Advanced: (item) => <button
                onClick={() => {
                    if (!item?.IsPayed) {
                        this.handleToggleChargeToModal(item)
                    } else {
                        this.props.dispatch(showModal('ViewContactCard', {ContactID: item?.ChargeToContactID}))
                    }
                }}
                className="hover:text-primary flex items-center gap-4 focus:outline-none focus:border focus:border-primary p-1 rounded-btn"
            >
                {item.ChargeToContact || item.ChargeToOrganization || "No item selected"}

                {!item?.IsPayed && (
                    <PencilAltOutlineIcon className="w-5 h-5 text-tm-gray-400"/>
                )}
            </button>
        }

        return (
            <React.Fragment>
                {!this.state.isInvoiceDialogHidden && (
                    // Create a new Bill
                    <ModalDefault
                        show={true}
                        widthClass={this.state.dialogType ? 'max-w-[1600px]' : 'max-w-md'}
                        title={<div
                            className="text-lg">{this.props.updateItem ? (this.props.isCopy ? translate('text.copy') : translate('text.update')) : translate('text.create_new')} {this.state.dialogType ? translate('text.' + this.state.dialogType) : ' ...'}</div>}
                        customHtmlLeft={this.state.ErrorMessage}

                        customHtmlRight={(this.props.updateItem && isReconciled && !this.props.isCopy) ? (
                            <div className={"rounded border border-emerald-500"}>
                                <div className={"p-2"}>
                                    Reconciliation date: <span
                                    className={"font-semibold"}>{toFrontDateTime(reconciliationDate)}</span>
                                </div>
                            </div>
                        ) : null}

                        closeButtonLabel={translate('btn.cancel')}
                        onClose={this.hideModal}
                        buttonLabel={this.state.dialogType ? translate('btn.save') : null}
                        buttonDisabled={!this.state.isDirty || !this.state.items.length || (isReceiptSubtype && isReconciled && !this.props.isCopy) || (isExpenseSubtype && isReconciled && !this.props.isCopy)}
                        onButtonClick={() => {
                            const fields = this.getValidFields()
                            const notePayableFields = this.getValidNotePayableFields()
                            if (!!fields && !!notePayableFields) {
                                this.props.onSubmit(
                                    fields,
                                    this.getDialogResource(),
                                    this.state.files,
                                    this.getDialogDocumentResource(),
                                    FieldsManager.getFieldKeyValues(this.state.notePayableFields)
                                )
                            }
                        }}
                    >

                        {(this.props.secondResource.isLoading || this.props.resource.isLoading) && (
                            <div className="p-5 text-center">
                                <LoaderSmall/>
                            </div>
                        )}

                        {!(this.props.secondResource.isLoading || this.props.resource.isLoading) && (
                            <div className="p-6 text-tm-gray-900 h-dialog-body">
                                {!this.state.dialogType && (
                                    <div className="text-center">
                                        {(!this.props.isExpenseDialogType) && (!this.props.isInvoiceDialogType) && (
                                            <div className="flex flex-col gap-4">
                                                <div className="btn btn-outline w-32 justify-center mx-auto"
                                                     onClick={() => {
                                                         this.setState({dialogType: 'invoice'})
                                                     }}>
                                                    <ArrowTrendingUpIcon className="absolute left-3 w-5 h-5"/>
                                                    {translate('btn.invoice')}
                                                </div>

                                                <div className="btn btn-outline w-32 justify-center mx-auto"
                                                     onClick={() => {
                                                         this.setState({dialogType: 'receipt'})
                                                     }}>
                                                    <ReceiptPercentIcon className="absolute left-3 w-5 h-5"/>
                                                    {translate('btn.receipt')}
                                                </div>

                                                <div className="btn btn-outline w-32 justify-center mx-auto"
                                                     onClick={() => {
                                                         this.setState({dialogType: 'bill'})
                                                     }}>
                                                    <DocumentMinusIcon className="absolute left-3 w-5 h-5"/>
                                                    {translate('btn.bill')}
                                                </div>

                                                <div className="btn btn-outline w-32 justify-center mx-auto"
                                                     onClick={() => {
                                                         this.setState({dialogType: 'expense'})
                                                     }}>
                                                    <ArrowTrendingDownIcon className="absolute left-3 w-5 h-5"/>
                                                    {translate('btn.expense')}
                                                </div>
                                            </div>
                                        )}

                                        {((this.props.isExpenseDialogType) || (this.props.isInvoiceDialogType)) && (
                                            <>
                                                <div className="btn btn-primary mx-4 w-28 justify-center"
                                                     onClick={() => {
                                                         this.setState({dialogType: this.props.isExpenseDialogType ? 'bill' : 'invoice'})
                                                     }}>{translate(this.props.isExpenseDialogType ? 'btn.bill' : 'btn.invoice')}
                                                </div>

                                                <div className="btn btn-primary mx-4 w-28 justify-center"
                                                     onClick={() => {
                                                         this.setState({dialogType: this.props.isExpenseDialogType ? 'expense' : 'receipt'})
                                                     }}>{translate(this.props.isExpenseDialogType ? 'btn.expense' : 'btn.receipt')}
                                                </div>
                                            </>
                                        )}
                                    </div>
                                )}

                                {this.state.dialogType && !this.props.resource.isLoading && (
                                    <React.Fragment>
                                        <div className="grid grid-cols-12 gap-8">
                                            <div className="col-span-6">
                                                <div className="grid grid-cols-12 gap-4">
                                                    <div className="col-span-6">

                                                        <div className="flex">
                                                            <label htmlFor=""
                                                                   className="font-bold leading-5">{this.isIncome() ? translate('field.ChargeTo') : translate('field.PayTo')} *
                                                            </label>

                                                        </div>

                                                        {(!this.props.updateItem || !!this.props.isCopy) && (
                                                            <ButtonsGroup
                                                                data={{
                                                                    1: this.props.translate('field.ContactID'),
                                                                    2: this.props.translate('field.OrganizationID')
                                                                }}
                                                                onChange={this.handleInputChange}
                                                                {...this.state.fields.ChargeTo}
                                                            />
                                                        )}

                                                    </div>

                                                    <div className="col-span-6">
                                                        {(this.state.fields.ChargeTo?.value == 2) && (
                                                            <div>
                                                                {(isOrganizationSelected) && (
                                                                    <label
                                                                        htmlFor=""
                                                                        className="flex font-bold h-5"
                                                                    >
                                                                        <FieldOptions addClass="ml-auto"
                                                                                      options={[
                                                                                          {
                                                                                              icon: InformationCircleIcon,
                                                                                              onClick: () => this.handleToggleOrganizationInfoDialog(this.state.fields?.OrganizationID?.value?.metadata),
                                                                                              isVisible: true
                                                                                          }
                                                                                      ]}
                                                                        />
                                                                    </label>
                                                                )}

                                                                <FieldDropdownSelect
                                                                    onChange={this.handleInputChange}
                                                                    addClass={"form-control" + " " + (!isOrganizationSelected ? "mt-5" : undefined)}
                                                                    {...this.state.fields.OrganizationID}
                                                                    defaultOptions={true}
                                                                    loadOptions={
                                                                        (inputValue, callback) => {
                                                                            axios.get(
                                                                                Env.getApiUrl('api/' + Resources.OrganizationsQuick, {
                                                                                    limit: 20,
                                                                                    offset: 0,
                                                                                    query: inputValue
                                                                                }),
                                                                                {
                                                                                    headers: {
                                                                                        'Authorization': 'Bearer ' + getJWT().access_token
                                                                                    }
                                                                                }
                                                                            )
                                                                                .then((response) => {
                                                                                    const result = processResponse(response)
                                                                                    if (result && result.status === 0) {
                                                                                        const list = result.data.list.map((it) => {
                                                                                            return {
                                                                                                label: it.LegalName + " - " + (it.AddressName ?? "")
                                                                                                    + ", " + (it.CityName ?? "")
                                                                                                    + " " + (getLookup('State')[it.StateID] ?? "") + " "
                                                                                                    + (it.PostalCode ?? ""),
                                                                                                value: it.OrganizationID,
                                                                                                metadata: it,
                                                                                            }
                                                                                        })

                                                                                        callback(list)
                                                                                    }
                                                                                })
                                                                        }
                                                                    }
                                                                />

                                                            </div>
                                                        )}

                                                        {(this.state.fields.ChargeTo?.value == 1) && (
                                                            <FieldDropdownSelect
                                                                onChange={this.handleInputChange}
                                                                addClass="form-control mt-5"
                                                                {...this.state.fields.ContactID}
                                                                defaultOptions={true}
                                                                key={this.state.QueryAccountTypeID}
                                                                loadOptions={
                                                                    (inputValue, callback) => {
                                                                        axios.get(
                                                                            Env.getApiUrl('api/' + Resources.ContactsQuick, {
                                                                                query: inputValue
                                                                            }),
                                                                            {
                                                                                headers: {
                                                                                    'Authorization': 'Bearer ' + getJWT().access_token
                                                                                }
                                                                            }
                                                                        )
                                                                            .then((response) => {
                                                                                const result = processResponse(response)
                                                                                if (result && result.status === 0) {
                                                                                    const list = result.data.list.map((it) => {
                                                                                        return {
                                                                                            label: it.FirstName + ' ' + it.LastName,
                                                                                            value: it.ContactID
                                                                                        }
                                                                                    })

                                                                                    callback(list)
                                                                                }
                                                                            })
                                                                    }
                                                                }
                                                            />
                                                        )}
                                                    </div>


                                                    {(this.state.fields.OrganizationID?.value) && (
                                                        <div className="col-span-full">
                                                            {this.renderAddressPreviewInfo()}
                                                        </div>
                                                    )}


                                                    <div className="col-span-4 relative z-[10]">
                                                        <label htmlFor=""
                                                               className="font-bold m-1">{translate('field.Date')} *
                                                        </label>

                                                        <FieldDate
                                                            onChange={this.handleInputChange} {...this.state.fields.Date}
                                                            addClass={'form-control'}
                                                            minDate={this.props?.defaults?.LockedDate}
                                                        />
                                                    </div>

                                                    <div className="col-span-4">
                                                        <label htmlFor=""
                                                               className="font-bold m-1">{translate('field.BookDate')} *
                                                        </label>

                                                        <FieldDate
                                                            onChange={this.handleInputChange} {...this.state.fields.BookDate}
                                                            addClass={'form-control'}/>
                                                    </div>

                                                    {(this.state.dialogType === 'invoice' || this.state.dialogType === 'bill') && (
                                                        <div className="col-span-4">
                                                            <label htmlFor=""
                                                                   className="font-bold m-1">{translate('field.DueDate')} *
                                                            </label>

                                                            <FieldDate
                                                                onChange={this.handleInputChange} {...this.state.fields.DueDate}
                                                                addClass={'form-control'}/>
                                                        </div>
                                                    )}


                                                    {(this.state.dialogType === 'receipt' || this.state.dialogType === 'expense') && (
                                                        <>
                                                            <div className="col-span-full">
                                                                <div className="">
                                                                    <label htmlFor=""
                                                                           className="font-bold">{translate('field.PaymentTypeID')} *
                                                                    </label>

                                                                    <FieldSelectSearch
                                                                        addClass="form-control"
                                                                        onChange={this.handleInputChange}
                                                                        values={getLookup('PaymentType', 'PaymentTypeID', 'PaymentType')}
                                                                        {...this.state.fields.PaymentTypeID}
                                                                        placeholder={''}
                                                                    />
                                                                </div>
                                                            </div>

                                                            {this.state.dialogType === 'receipt' && this.state.fields.CheckNumber.type === 'text' && (
                                                                <div className="col-span-full">
                                                                    <FieldContainer
                                                                        item={this.state.fields.CheckNumber}
                                                                        translate={translate}
                                                                    >
                                                                        <FieldText
                                                                            addClass="form-control"
                                                                            onChange={this.handleInputChange}
                                                                            {...this.state.fields.CheckNumber}
                                                                        />
                                                                    </FieldContainer>
                                                                </div>
                                                            )}

                                                            <div className="col-span-full">
                                                                <div className="">
                                                                    <label htmlFor=""
                                                                           className="font-bold m-1">{
                                                                        this.state.dialogType === 'receipt' ? translate('field.DepositTo') :
                                                                            translate('field.PayFrom')
                                                                    } *
                                                                    </label>

                                                                    <FieldDropdownSelect
                                                                        onChange={this.handleInputChange}
                                                                        addClass="form-control mb-3"
                                                                        {...this.state.fields.AccountID}
                                                                        defaultOptions={true}
                                                                        key={this.state.QueryAccountTypeID}
                                                                        loadOptions={
                                                                            (inputValue, callback) => {
                                                                                axios.get(
                                                                                    Env.getApiUrl('api/' + Resources.AccountsQuick, {
                                                                                        query: inputValue,
                                                                                        AccountTypeID: this.state.QueryAccountTypeID
                                                                                    }),
                                                                                    {
                                                                                        headers: {
                                                                                            'Authorization': 'Bearer ' + getJWT().access_token
                                                                                        }
                                                                                    }
                                                                                )
                                                                                    .then((response) => {
                                                                                        const result = processResponse(response)
                                                                                        if (result && result.status === 0) {
                                                                                            const list = result.data.list.map((it) => {
                                                                                                return {
                                                                                                    label: it.AccountName,
                                                                                                    value: it.AccountID
                                                                                                }
                                                                                            })

                                                                                            callback(list)
                                                                                        }
                                                                                    })
                                                                            }
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}

                                                    <div className="col-span-4 col-start-1">
                                                        <label htmlFor=""
                                                               className="font-bold m-1">{translate('field.RefNumber')} {this.state.AutoGenerateRef ? '' : '*'}
                                                        </label>

                                                        <FieldText
                                                            onChange={this.handleInputChange}
                                                            {...this.state.fields.RefNumber}
                                                            disabled={this.state.AutoGenerateRef}
                                                            addClass={'form-control'}/>

                                                    </div>

                                                    <div className="col-span-8">
                                                        {this.isIncome() && (!this.props.updateItem || !!this.props.isCopy) && (
                                                            <div className="flex items-center pt-5 h-14">
                                                                <FieldSwitch
                                                                    // addClass={isActiveDay ? "" : "border border-tm-gray-300"}
                                                                    value={this.state.AutoGenerateRef}
                                                                    name="Active"
                                                                    onChange={() => this.setState({
                                                                        AutoGenerateRef: !this.state.AutoGenerateRef
                                                                    })}
                                                                />

                                                                <p
                                                                    className="text-tm-gray-500 text-small mb-0 mt-sm-0 ml-2">{translate(`field.AutoGenerateRef`)}</p>
                                                            </div>
                                                        )}
                                                    </div>

                                                    <div className="col-span-6">
                                                        <div className="">
                                                            <label htmlFor=""
                                                                   className="font-bold m-1">{translate('field.office_id')} *
                                                            </label>

                                                            <FieldDropdownSelect
                                                                onChange={this.handleInputChange}
                                                                addClass="form-control mb-3"
                                                                {...this.state.fields.OfficeID}
                                                                defaultOptions={true}
                                                                loadOptions={
                                                                    (inputValue, callback) => {
                                                                        axios.get(
                                                                            Env.getApiUrl('api/' + Resources.OfficesQuick, {query: inputValue}),
                                                                            {
                                                                                headers: {
                                                                                    'Authorization': 'Bearer ' + getJWT().access_token
                                                                                }
                                                                            }
                                                                        )
                                                                            .then((response) => {
                                                                                const result = processResponse(response)
                                                                                if (result && result.status === 0) {
                                                                                    const list = result.data.list.map((it) => {
                                                                                        return {
                                                                                            label: it.OfficeName,
                                                                                            value: it.OfficeID
                                                                                        }
                                                                                    })
                                                                                    callback(list)
                                                                                }
                                                                            })
                                                                    }
                                                                }
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-span-6">
                                                        <div className="">
                                                            <label
                                                                htmlFor=""
                                                                className="flex font-bold "
                                                            >
                                                                {translate('field.expense_group_id')} *
                                                                <FieldOptions addClass="ml-auto"
                                                                              options={[
                                                                                  {
                                                                                      icon: InformationCircleIcon,
                                                                                      onClick: () => this.handleGroupInfoClick(this.state.fields?.ContactGroupID?.value?.metadata),
                                                                                      isVisible: !!this.state.fields?.ContactGroupID?.value?.value
                                                                                  }
                                                                              ]}
                                                                />
                                                            </label>

                                                            <FieldDropdownSelect
                                                                onChange={this.handleInputChange}
                                                                addClass="form-control mb-3"
                                                                {...this.state.fields.ContactGroupID}
                                                                menuPlacement={"top"}
                                                                defaultOptions={true}
                                                                loadOptions={
                                                                    (inputValue, callback) => {
                                                                        axios.get(
                                                                            Env.getApiUrl('api/' + Resources.ContactGroupsQuick, {query: inputValue}),
                                                                            {
                                                                                headers: {
                                                                                    'Authorization': 'Bearer ' + getJWT().access_token
                                                                                }
                                                                            }
                                                                        )
                                                                            .then((response) => {
                                                                                const result = processResponse(response)
                                                                                if (result && result.status === 0) {
                                                                                    const list = result.data.list.map((it) => {
                                                                                        return {
                                                                                            label: it.ContactGroupName,
                                                                                            value: it.ContactGroupID,
                                                                                            metadata: it
                                                                                        }
                                                                                    })
                                                                                    callback(list)
                                                                                }
                                                                            })
                                                                    }
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-span-6">
                                                <div className="grid grid-cols-12 gap-4">
                                                    {this.state.fields?.InternalNotes?.type === 'hidden' && (
                                                        <div
                                                            className="col-span-4 flex items-center justify-end pt-5">
                                                            <button
                                                                onClick={this.toggleInternalNotesVisibility}
                                                                className="btn btn-outline w-full justify-center"
                                                            >
                                                                <PlusIcon
                                                                    className="w-5 h-5 text-tm-gray-600 mr-1 -ml-1"/>
                                                                Internal notes
                                                            </button>
                                                        </div>
                                                    )}

                                                    {this.state.fields?.ExternalNotes?.type === 'hidden' && (this.state.dialogType === 'invoice' || this.state.dialogType === 'receipt') && (
                                                        <div
                                                            className="col-span-4 flex items-center justify-end pt-5"
                                                        >
                                                            <button
                                                                onClick={this.toggleExternalNotesVisibility}
                                                                className="btn btn-outline w-full justify-center"
                                                            >
                                                                <PlusIcon
                                                                    className="w-5 h-5 text-tm-gray-600 mr-1 -ml-1"/>
                                                                External notes
                                                            </button>
                                                        </div>
                                                    )}

                                                    <div className="text-right col-start-9 col-span-4">
                                                        <div className="font-bold">
                                                            <div
                                                                className="text-lg">{translate('field.total_amount')}</div>
                                                        </div>
                                                        <div
                                                            className="text-lg">{numberWithCommas(this.TotalAmount)}</div>
                                                    </div>

                                                    {(this.state.dialogType === 'expense' || this.state.dialogType === 'bill') && (
                                                        <>
                                                            {this.state.fields?.InternalNotes?.type !== 'hidden' && (
                                                                <div className="col-span-full">
                                                                    <div
                                                                        className="font-bold h-5 flex"
                                                                    >
                                                                        {translate('field.InternalNotes')}
                                                                        <button
                                                                            onClick={this.toggleInternalNotesVisibility}
                                                                            className="ml-auto px-1 rounded-btn hover:text-primary-tint"
                                                                        >
                                                                            <XMarkIcon className="w-5 h-5"/>
                                                                        </button>
                                                                    </div>

                                                                    <FieldTextarea
                                                                        addClass={'invoice-textarea-height form-control pt-1 pb-1 whitespace-pre-wrap'}
                                                                        rows={5}
                                                                        onChange={this.handleInputChange}
                                                                        {...this.state.fields.InternalNotes}
                                                                    />
                                                                </div>
                                                            )}
                                                        </>
                                                    )}


                                                    {(this.state.dialogType === 'invoice' || this.state.dialogType === 'receipt') && (
                                                        <>
                                                            {this.state.fields?.InternalNotes?.type !== 'hidden' && (
                                                                <div className="col-span-full">
                                                                    <div
                                                                        className="font-bold h-5 flex"
                                                                    >
                                                                        {translate('field.InternalNotes')}
                                                                        <button
                                                                            onClick={this.toggleInternalNotesVisibility}
                                                                            className="ml-auto px-1 rounded-btn hover:text-primary-tint"
                                                                        >
                                                                            <XMarkIcon className="w-5 h-5"/>
                                                                        </button>
                                                                    </div>
                                                                    <FieldTextarea
                                                                        addClass={'invoice-textarea-height form-control pt-1 pb-1 whitespace-pre-wrap'}
                                                                        rows={5}
                                                                        onChange={this.handleInputChange}
                                                                        {...this.state.fields.InternalNotes}
                                                                    />
                                                                </div>
                                                            )}

                                                            {this.state.fields?.ExternalNotes?.type !== 'hidden' && (
                                                                <>
                                                                    <div className="col-span-full">
                                                                        <div
                                                                            className="font-bold h-5 flex">
                                                                            {translate('field.ExternalNotes')}
                                                                            <button
                                                                                onClick={this.toggleExternalNotesVisibility}
                                                                                className="ml-auto px-1 rounded-btn hover:text-primary-tint"
                                                                            >
                                                                                <XMarkIcon className="w-5 h-5"/>
                                                                            </button>
                                                                        </div>

                                                                        <FieldTextarea
                                                                            addClass={'invoice-textarea-height form-control pt-1 pb-1 whitespace-pre-wrap'}
                                                                            rows={5}
                                                                            onChange={this.handleInputChange}
                                                                            {...this.state.fields.ExternalNotes}
                                                                        />
                                                                    </div>
                                                                    <div className="col-span-full">
                                                                        <InfoBar
                                                                            message={'External notes will appear on an invoice when printed.'}/>
                                                                    </div>
                                                                </>
                                                            )}


                                                        </>
                                                    )}


                                                    <div className="col-span-full pb-8">
                                                        {haveDocument && !!this.props.updateItem && (
                                                            <div className={"flex items-center"}>

                                                                <button onClick={() => {
                                                                    this.handleToggleViewModal(false)
                                                                }}>
                                                                    <EyeIcon className={'w-5 h-5'}/>
                                                                </button>


                                                                <div
                                                                    className={classNames(this.state?.files[0]?.name ? 'line-through' : undefined, 'font-bold ml-2 mt-0.5')}>{translate('text.current_document')}</div>

                                                                {/*<button*/}
                                                                {/*    onClick={this.removeAttachment}*/}
                                                                {/*    className="btn-text ml-4"*/}
                                                                {/*>( Remove )</button>*/}
                                                            </div>
                                                        )}
                                                        <label
                                                            className="font-bold m-1">{haveDocument ? translate('text.replace_document') : translate('text.upload_attachment')}</label>

                                                        <Dropzone
                                                            onDrop={(acceptedFiles) => {
                                                                this.onDropAccepted(acceptedFiles)
                                                            }}
                                                            // onDragEnter={this.onDragEnter}
                                                            onDragLeave={this.onDragLeave}
                                                            onDropAccepted={this.onDrop}
                                                            maxFiles={1}
                                                            accept={'image/jpg,image/jpeg, image/png, .pdf'}
                                                            maxSize={MAXIMUM_DOCUMENT_UPLOAD_SIZE}
                                                            multiple={false}
                                                        >
                                                            {({getRootProps, getInputProps}) => (
                                                                <section>
                                                                    <div {...getRootProps()}
                                                                         className={
                                                                             'px-6 py-10 rounded-card hover:border-primary border border-dashed border-tm-gray-400 mb-5 text-center text-break flex items-center justify-center' + (this.state.dropzoneActive ? ' active' : '')}>
                                                                        <input {...getInputProps()} />
                                                                        <div>
                                                                            <p>
                                                                                <em>{translate('field.image_types_and_pdf')}</em>
                                                                            </p>

                                                                            <em>{files.length !== 0 ? files : translate('text.no_file_selected')}</em>
                                                                        </div>
                                                                    </div>
                                                                </section>
                                                            )}
                                                        </Dropzone>

                                                        {!!files.length && (
                                                            <FileList
                                                                onFileView={() => this.handleToggleViewModal(true)}
                                                                files={this.state.files}
                                                                onFileDelete={(file, i) => this.removeDocument(i)}
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                            </div>


                                        </div>

                                        {this.state.dialogType === 'bill' && (
                                            <div className="grid gap-4 grid-cols-12 relative z-[9]">
                                                {fieldsToHtml(Object.values(this.state.notePayableFields), translate, this.handleNotePayableChange, this.selects)}

                                                <div className="col-span-full pt-6 -mx-4">
                                                    <AccountingLoadBreakdown
                                                        onShowMonthlyBreakdownClick={this.handleShowMonthlyBreakdownClick}
                                                        show={this.state.notePayableFields?.IsDelayedPayment?.value}
                                                        amount={numberWithCommasToBack(this.TotalAmount)}
                                                        cachedChangedFields={this.state.notePayableFields}
                                                        translate={translate}
                                                    />

                                                    <AccountingMonthlyBreakdown
                                                        show={this.state.isMonthlyBreakdownOpen}
                                                        amount={numberWithCommasToBack(this.TotalAmount)}
                                                        onShowMonthlyBreakdownClick={this.handleShowMonthlyBreakdownClick}
                                                        cachedChangedFields={this.state.notePayableFields}
                                                        translate={translate}
                                                    />
                                                </div>
                                            </div>
                                        )}

                                        <div className="bg-inverse mt-6">
                                            <div className="">
                                                <div className="row">
                                                    <div
                                                        className="col-12 bg-tm-gray-100 py-5 rounded-card shadow-card border border-tm-gray-300">
                                                        {!isIncomeType && (
                                                            <InvoiceExpenseItemTable
                                                                handleSelectFirstElement={this.handleSelectFirstElement}
                                                                className="invoice-table-rounded"
                                                                getFields={this.getExpenseItemField}
                                                                addBtnRef={this.addBtnRef}
                                                                items={this.state.items}
                                                                onRemoveItem={this.removeItem}
                                                                onHandleItemChange={this.handleItemInputChange}
                                                                onItemDragStart={this.handleItemDragStart}
                                                                onItemDragEnd={this.handleItemDragEnd}
                                                                onEnterItem={this.handleEnterItem}
                                                                draggedItemIndex={this.state.draggedItemIndex}
                                                                onCreateProductService={this.createProductServiceDialog}
                                                                translate={this.props.translate}
                                                                onAddNewItem={this.addNewExpenseItem}
                                                                onEditItem={this.handleEditExpenseItem}
                                                                isIncome={this.isIncome()} // true
                                                                renderCells={renderCells}
                                                                dispatch={this.props.dispatch}
                                                            />
                                                        )}

                                                        {isIncomeType && (
                                                            <InvoiceIncomeItemTable
                                                                handleSelectFirstElement={this.handleSelectFirstElement}
                                                                getFields={this.getIncomeItemFields}
                                                                addBtnRef={this.addBtnRef}
                                                                items={this.state.items}
                                                                className="invoice-table-rounded"
                                                                onRemoveItem={this.removeItem}
                                                                onHandleItemChange={this.handleItemInputChange}
                                                                onItemDragStart={this.handleItemDragStart}
                                                                onItemDragEnd={this.handleItemDragEnd}
                                                                onEnterItem={this.handleEnterItem}
                                                                draggedItemIndex={this.state.draggedItemIndex}
                                                                onCreateProductService={this.createProductServiceDialog}
                                                                translate={this.props.translate}
                                                                onAddNewItem={this.addNewIncomeItem}
                                                                onEditItem={this.handleEditIncomeItem}
                                                                renderCells={renderCells}
                                                                dispatch={this.props.dispatch}
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                )}
                            </div>
                        )}
                    </ModalDefault>
                )}

                <ModalDefault
                    show={!!this.state.filePreview}
                    widthClass={'max-w-7xl'}
                    limitHeight={true}
                    title={translate('modal_heading.preview_document')}

                    close={this.handleToggleViewModal}
                    closeButtonLabel={translate('Close')}
                    onClose={this.handleToggleViewModal}
                >
                    {!!this.state.files[0] && !!this.state.attachedDocument && (
                        <FileViewer
                            fileType={this.state.files[0]?.name.split('.').pop()}
                            filePath={this.state.files[0]?.preview}
                            onError={(e) => {
                                console.log(e)
                            }}/>
                    )}

                    {!!haveDocument && !this.state.attachedDocument && (
                        <FileViewer
                            fileType={haveDocument.split('.').pop()}
                            filePath={Env.getApiUrl("api/" + (this.props.updateItem?.ExpenseID ? Resources.ExpenseDocuments : Resources.InvoicesDocuments), this.props.updateItem?.ExpenseID ? {
                                id: this.props.updateItem?.ExpenseID,
                                token: getJWT().access_token
                            } : {
                                id: this.props.updateItem?.InvoiceID,
                                token: getJWT().access_token
                            })}
                            onError={(e) => {
                                console.log(e)
                            }}/>
                    )}
                </ModalDefault>

                {(this.state.isOrganizationInfoDialogOpen && checkPerm(Resources.OrganizationsQuick, READ_PERM) &&
                    <OrganizationInfoDialog
                        show={this.state.isOrganizationInfoDialogOpen}
                        translate={translate}
                        organizationID={this.state.selectedOrganization?.OrganizationID || this.getOrganizationMetadata().OrganizationID}
                        handleClose={() => this.setState({
                            isOrganizationInfoDialogOpen: false
                        })}
                    />)}

                <ModalSaveResource
                    title={'Select charge to'}
                    className="max-w-lg"
                    gridColsClass="grid-cols-3"
                    handleInputChange={this.handleChargeToInputChange}
                    show={this.state.isChargeToModalOpen}
                    onClose={() => this.setState({isChargeToModalOpen: false})}
                    fields={this.getChargeToFields(this.state.ChargeTo)}
                    onSubmit={(params) => {
                        if (params) {
                            let allFields = this.state.items;
                            let fields = allFields[this.state.ChargeTo.index];

                            fields.Advanced.value = params.Advanced;
                            fields.Advanced.type = 'switch';

                            fields.ChargeToOrganizationID.value = {
                                label: params.ChargeToOrganization,
                                value: params.ChargeToOrganizationID
                            };
                            fields.ChargeToContactID.value = {
                                label: params.ChargeToContact,
                                value: params.ChargeToContactID
                            };
                            if (params.ChargeToContactID) {
                                fields.ChargeToOrganizationID.value = "";
                                fields.ChargeToContactID.type = 'select-search';
                            } else if (params.ChargeToOrganizationID) {
                                fields.ChargeToContactID.value = "";
                                fields.ChargeToOrganizationID.type = 'select-search';
                            }

                            if (params.ChargeTo) {
                                fields.ChargeTo.value = params.ChargeTo;
                                fields.ChargeTo.type = 'button-group';
                            }

                            if (params.Advanced === 0) {
                                fields.ChargeToContactID.value = "";
                                fields.ChargeToOrganizationID.value = "";
                                fields.ChargeTo.value = 1;
                            }

                            if (params.Advanced === 1 && (params.ChargeToContactID === "" && params.ChargeToOrganizationID === "")) {
                                fields.Advanced.value = 0;
                            }

                            allFields[this.state.ChargeTo.index] = fields;
                            this.setState({
                                items: allFields,
                                isDirty: true
                            });
                        }
                        this.handleToggleChargeToModal();
                    }
                    }
                    metadata={{
                        ChargeToOrganizationID: {
                            api: 'api/' + Resources.OrganizationsQuick,
                            query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                            searchMap: (item) => ({
                                label: item.LegalName,
                                value: item.OrganizationID,
                                Organization: item
                            })
                        },
                        ChargeToContactID: {
                            api: 'api/' + Resources.ContactsQuick,
                            query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                            searchMap: (item) => ({
                                label: item.FirstName + " " + item.LastName,
                                value: item.ContactID,
                                Contact: item
                            })
                        }

                    }}
                    translate={translate}
                />
                {this.state.isProductServiceInfoDialogVisible && (
                    <ProductServiceInformationDialog
                        {...this.props}
                        isExpenseDialogType={this.props.isExpenseDialogType}
                        isInvoiceDialogType={this.props.isInvoiceDialogType}
                        setCreatedItemName={this.setCreatedItemName}
                        onCloseProductServiceInfoDialog={this.closeProductServiceInfoDialog}
                        name={this.state.ProductServiceInfoName}
                        buyAsset={this.props.isExpenseDialogType}
                        sellAsset={this.props.isInvoiceDialogType}
                    />
                )}

                <GroupsViewDialog
                    groupID={this.state.fields?.ContactGroupID?.value?.value}
                    isGroupInfoDialogOpen={this.state.isGroupInfoDialogOpen}
                    groupInfoData={this.state.groupInfoData}
                    onClose={() => this.setState({
                        isGroupInfoDialogOpen: false
                    })}
                    translate={translate}
                />

                <InventoryReportDialog
                    isReportDialogOpen={this.state.reportModalOpen}
                    dialogResource={this.props.dialogResource}
                    resource={Resources.ProductServiceReport}
                    dispatch={this.props.dispatch}
                    thirdResource={this.props.thirdResource}
                    translate={translate}
                    setIsReportDialogOpen={this.handleViewReportClick}
                    selectedReportItem={this.state.selectedItem}
                />
            </React.Fragment>
        )
    }
}

export default connect(state => state)(InvoiceDialog)
