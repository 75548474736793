import React, {useEffect} from "react";
import {classNames, updateQueryFields} from "../../../util/util-helpers";
import ModalDefault from "../modal-default";
import ResourceTable from "../../resource-table";
import TableCardFooter from "../../resource-table/table-components/table-card-footer";
import Pagination from "../../resource-table/table-components/pagination";
import {getDialogResource} from "../../../../data/actions/dialogResource";
import LocalStorage from "../../../util/localStorage";
import {ArrowPathIcon} from "@heroicons/react/24/outline";
import {useDispatch, useSelector} from "react-redux";
import useQuery from "../../../../hooks/use-query";
import {FieldsManager} from "../../../../data/services/fields";
import FieldsForm from "../../fields/fields-form";

// TODO: This component is work in progress
// Test no records
// Todo make new filter component

export default function ResourceTableDialogNew({
                                                   show,
                                                   title,
                                                   resourcePath,
                                                   fields,
                                                   translate,
                                                   tableKey, // Required fields
                                                   widthClass = "max-w-5xl",
                                                   onView,
                                                   onCreate,
                                                   onEdit,
                                                   onDelete,
                                                   onRowClick,
                                                   getCustomActions,
                                                   options,
                                                   getQueryFields,
                                                   querySelects,
                                                   maxHeightClass = "max-h-[calc(100vh-12rem)]",
                                                   hideRowsPerPage = true,
                                                   queryStoragePath,
                                                   hasViewPerm = true,
                                                   hasEditPerm = true,
                                                   hasDeletePerm = true,
                                                   hasRestorePerm = true,
                                                   onClose,
                                                   htmlAfter,
                                                   queryRef
                                               }) {

    const dispatch = useDispatch();

    const [queryFields, setQueryFields] = useQuery(getQueryFields, queryStoragePath);
    let query = FieldsManager.getFieldKeyValues(queryFields);

    const resource = useSelector(state => state.dialogResource);

    const data = resource?.data?.list ?? [];
    const count = resource?.data?.count;
    const isLoading = !!resource?.isLoading;


    function handleQueryChange(name, value) {
        setQueryFields(updateQueryFields(queryFields, name, value))
    }

    /** Data Events
     ================================================================= */
    function fetchData() {
        const requestData = {
            user: LocalStorage.get("user"),
            resource: resourcePath,
            query: query
        }

        // create new resource if dialog resource is conflicting

        dispatch(getDialogResource(requestData))
    }


    /** Lifecycle
     ================================================================= */
    useEffect(() => {
        if (show) {
            if (queryRef) {
                queryRef.current = query; // for passing query to parent without update state
            }
            fetchData();
        }
    }, [show, queryFields])

    return (
        <React.Fragment>
            <ModalDefault
                show={show}
                widthClass={widthClass}
                title={title}
                onClose={onClose}
                limitHeight={false}
                hideDialogFooter={true}
            >
                {!!getQueryFields && (
                    <div className="flex">
                        <div className="grid px-4 py-2 gap-4 grid-cols-6 md:grid-cols-12 z-30 relative grow">
                            <FieldsForm
                                fieldsState={queryFields}
                                onChange={handleQueryChange}
                                translate={translate}
                                selects={querySelects}
                            />

                            <div className={"col-start-6 md:col-start-12 flex justify-end"}>
                                <button
                                    className={
                                        classNames(
                                            "btn btn-header z-10",
                                        )
                                    }
                                    onClick={fetchData}
                                >
                                    <ArrowPathIcon className={
                                        classNames(
                                            "w-5 h-5",
                                            isLoading ? "animate-spin" : undefined,
                                        )
                                    }/>
                                </button>
                            </div>
                        </div>
                    </div>
                )}

                <ResourceTable
                    data={data}
                    maxHeightClass={maxHeightClass}
                    fields={fields}
                    translate={translate}
                    isLoading={isLoading}

                    hasViewPerm={hasViewPerm}
                    hasEditPerm={hasEditPerm}
                    hasDeletePerm={hasDeletePerm}
                    hasRestorePerm={hasRestorePerm}

                    options={options}
                    queryFields={queryFields}
                    tableKey={tableKey}
                    onSortChange={(colName) => handleQueryChange('sortBy', colName)}
                    onRowClick={onRowClick}
                    onView={onView}
                    onCreate={onCreate}
                    onEdit={onEdit}
                    onDelete={onDelete}
                    actions={getCustomActions}
                />

                <TableCardFooter
                    show={!(!data.length && !isLoading)}
                >
                    <Pagination
                        count={count}
                        hideRowsPerPage={hideRowsPerPage}
                        queryFields={queryFields}
                        isLoading={isLoading}
                        handleQueryChange={handleQueryChange}
                        translate={translate}
                    />
                </TableCardFooter>

                {/*<NoRecords*/}
                {/*    addClass="py-12"*/}
                {/*    show={data.length === 0 && !isLoading}*/}
                {/*    title={'No matching records found'}*/}
                {/*    text={!!noRecordsText && noRecordsText(currentQuery)}*/}
                {/*    btnLabel={!!noRecordsBtnLabel && noRecordsBtnLabel(currentQuery)}*/}
                {/*    canCreate={hasQuery && !areFiltersActive}*/}
                {/*    onBtnClick={() => noRecordsBtnClick(currentQuery)}*/}
                {/*/>*/}

                {htmlAfter}
            </ModalDefault>
        </React.Fragment>
    );
}

